import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../PercentageAnalysis/PercentageAnalysis.css'
import { memo } from "react";
import './MontlyAnalysis.css'
import { MergePerObject, ObjectToKeyValueArr, getMostRepeatingArrayEle, getWeekNumber } from '../../Actions/StaticFunction';
import MonthlyAnalysisNote from '../MonthlyAnalysisNote/MonthlyAnalysisNote';
import axios from 'axios';
import { LoadUserNoLoad } from '../../Actions/User';
import { useAlert } from 'react-alert'

const month = {
  "01":"January",
  "02":"February",
  "03":"March",
  "04":"April",
  "05":"May",
  "06":"June",
  "07":"July",
  "08":"August",
  "09":"September",
  "10":"October",
  "11":"November",
  "12":"December",
}


const MontlyAnalysis = ({ setShowMontlyAnalysis,DATA,ALLFILENAMES }) => {
  const [allData,setAllData] = useState([]);
  const [selectedMonth,setSelectedMonth] = useState("");
  const [selectedMonthsData,setSelectedMonthsData] = useState([]);
  const [showAddNotePopup,setShowAddNotePopup] = useState(false);
  const [note,setNote] = useState(null);
  const [showCandleDatesId,setShowCandleDatesId] = useState(true);
  const [hoveredCandleDates,setHoveredCandleDates] = useState([]);
  const alert = useAlert();

  const dispatch = useDispatch();

  const handleAddNote = (data) => {
    const {perGainWeek,perLossWeek,PriceGainWeek,PriceLossWeek,desc} = data;
    const str = `Highest Percent Gain week - ${perGainWeek} <br/> Highest percent Loss week - ${perLossWeek} <br/> Highest price week - ${PriceGainWeek} <br/> Lowest price week- ${PriceLossWeek} <br/> ${desc}`
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    try {
        const obj = {
          note: str,
        }
        axiosInstance.post("/postnotes", obj, {
          headers: {
            'x-access-token': localStorage.getItem('token')
          }
        }).then(res => {
          if (res.data === "Notes Added" || res.data === "Notes Updated") {
            dispatch(LoadUserNoLoad());
            alert.success(res.data)
            setShowAddNotePopup(false)
          }
        })
    } catch (error) {
      alert.error(error.response.data.message)
    }
  //   // const data = 
  }

   useEffect(() => {
    // sort the data by the date i.e. jan to dec
  let sortedData = DATA.map(ele =>  ele.sort((a,b) => a.date > b.date ? 1 : -1));

  // remove duplicate record
  sortedData = sortedData.map(eachFile => {
    const fd = eachFile.filter((ele,index) => eachFile.findIndex(item => item.date === ele.date) === index);
    return fd;
  })

 
 
    // contain all file data .i.e all file final data
    const AllFileData = [];

    sortedData.forEach(singleFile => {

      const singleFileData = [];  // conatin sigle file data divided into monthwise manner 
       const FinalSingleFileData = [];  // contain single file final all data

    //    find all months within the single file
       const Dates = [...new Set(singleFile.map(ele => ele.date.split("-")[0]+"-"+ele.date.split("-")[1]))];
      //  filter the month wise data and insert into singleFileData
       Dates.forEach(month => {
          const data = singleFile.filter(ele => ele.date.includes(month));
          singleFileData.push({
            month,
            data
          })
       })  
       
      //  we get monthwise data after that calculate all feild;
  
      // perform opration on monthwise data by taking single month
      singleFileData.forEach(eachMonth => {

        //  every month data
         const monthData = eachMonth.data;

        //  all below feild are specific for each month data 
        // variable section start
         let redcandleCount = 0;
          let greenCandleCount = 0;
          let highestPerGainDay={percent:0,date:""};
          let highestPerLossDay={percent:0,date:""};
          let firstDayOpenPrice = monthData[0].open_price;
          let lastDayClosePrice = monthData[monthData.length-1].close_price;
          let highestPriceInMonth = {highPrice:monthData[0].high,date:monthData[0].date};
          let lowestPriceInMonth = {lowPrice:monthData[0].low,date:monthData[0].date};
          let dataAllCandle = [];

          let dailyPercentGain = {
            "0.5":{count:0,dates:[]},
            "1":{count:0,dates:[]},
            "1.5":{count:0,dates:[]},
            "2":{count:0,dates:[]},
            "3":{count:0,dates:[]},
            "4":{count:0,dates:[]},
            "5":{count:0,dates:[]},
            "6":{count:0,dates:[]},
            "7":{count:0,dates:[]},
            "8":{count:0,dates:[]},
            "9":{count:0,dates:[]},
            "10":{count:0,dates:[]},
            "11":{count:0,dates:[]},
            "12":{count:0,dates:[]},
            "13":{count:0,dates:[]},
            "14":{count:0,dates:[]},
            "15":{count:0,dates:[]},
            "16":{count:0,dates:[]},
            "17":{count:0,dates:[]},
            "18":{count:0,dates:[]},
            "19":{count:0,dates:[]},
            "20":{count:0,dates:[]},
            "other":{count:0,dates:[]},
           }

           let dailyPercentLoss =  {
            "0.5":{count:0,dates:[]},
            "1":{count:0,dates:[]},
            "1.5":{count:0,dates:[]},
            "2":{count:0,dates:[]},
            "3":{count:0,dates:[]},
            "4":{count:0,dates:[]},
            "5":{count:0,dates:[]},
            "6":{count:0,dates:[]},
            "7":{count:0,dates:[]},
            "8":{count:0,dates:[]},
            "9":{count:0,dates:[]},
            "10":{count:0,dates:[]},
            "11":{count:0,dates:[]},
            "12":{count:0,dates:[]},
            "13":{count:0,dates:[]},
            "14":{count:0,dates:[]},
            "15":{count:0,dates:[]},
            "16":{count:0,dates:[]},
            "17":{count:0,dates:[]},
            "18":{count:0,dates:[]},
            "19":{count:0,dates:[]},
            "20":{count:0,dates:[]},
            "other":{count:0,dates:[]},
           }

           
          let dayscount = {
            "1":{green:0,red:0,dates:[]},
            "2":{green:0,red:0,dates:[]},
            "3":{green:0,red:0,dates:[]},
            "4":{green:0,red:0,dates:[]},
            "5":{green:0,red:0,dates:[]},
            "6":{green:0,red:0,dates:[]},
            "7":{green:0,red:0,dates:[]},
            "8":{green:0,red:0,dates:[]},
            "9":{green:0,red:0,dates:[]},
            "10":{green:0,red:0,dates:[]},
            "11":{green:0,red:0,dates:[]},
            "12":{green:0,red:0,dates:[]},
            "13":{green:0,red:0,dates:[]},
            "14":{green:0,red:0,dates:[]},
            "15":{green:0,red:0,dates:[]},
            "16":{green:0,red:0,dates:[]},
            "17":{green:0,red:0,dates:[]},
            "18":{green:0,red:0,dates:[]},
            "19":{green:0,red:0,dates:[]},
            "20":{green:0,red:0,dates:[]},
          }
               // variable section end
        
         monthData.forEach(ele => {
            //  calculate highest price gain in month
            if(highestPriceInMonth.highPrice < ele.high){
              highestPriceInMonth.highPrice = ele.high;
              highestPriceInMonth.date = ele.date;
            }

              //  calculate lowest price drop in month
              if(lowestPriceInMonth.lowPrice > ele.low){
                lowestPriceInMonth.lowPrice = ele.low;
                lowestPriceInMonth.date = ele.date;
               }
          

          if (ele.open_price < ele.close_price) {
            // green candle
            dataAllCandle.push({candle:'green',date:ele.date});
            greenCandleCount++;
              //  calculate percent gain
           const perGain = (ele.high - ele.open_price) / (ele.open_price / 100);
          //   check the daily percent gain is greater than highest 
           if(highestPerGainDay.percent < perGain){
            highestPerGainDay.percent =perGain
            highestPerGainDay.date=ele.date;
           }
          // add daily percentage gain days count data
          let dailyPercentValue = perGain;
          if(dailyPercentValue <= 0.5) dailyPercentGain["0.5"] = {count:dailyPercentGain["0.5"].count+1,dates:[...dailyPercentGain["0.5"].dates,{date:ele.date,type:"gain"}]};
          else if(dailyPercentValue <= 1 && dailyPercentValue> 0.5) dailyPercentGain["1"] ={count:dailyPercentGain["1"].count+1,dates:[...dailyPercentGain["1"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 1.5 && dailyPercentValue> 1) dailyPercentGain["1.5"] = {count:dailyPercentGain["1.5"].count+1,dates:[...dailyPercentGain["1.5"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 2 && dailyPercentValue> 1.5) dailyPercentGain["2"] = {count:dailyPercentGain["2"].count+1,dates:[...dailyPercentGain["2"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 3 && dailyPercentValue> 2) dailyPercentGain["3"] = {count:dailyPercentGain["3"].count+1,dates:[...dailyPercentGain["3"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 4 && dailyPercentValue> 3) dailyPercentGain["4"] = {count:dailyPercentGain["4"].count+1,dates:[...dailyPercentGain["4"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 5 && dailyPercentValue> 4) dailyPercentGain["5"] = {count:dailyPercentGain["5"].count+1,dates:[...dailyPercentGain["5"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 6 && dailyPercentValue> 5) dailyPercentGain["6"] = {count:dailyPercentGain["6"].count+1,dates:[...dailyPercentGain["6"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 7 && dailyPercentValue> 6) dailyPercentGain["7"] = {count:dailyPercentGain["7"].count+1,dates:[...dailyPercentGain["7"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 8 && dailyPercentValue> 7) dailyPercentGain["8"] = {count:dailyPercentGain["8"].count+1,dates:[...dailyPercentGain["8"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 9 && dailyPercentValue> 8) dailyPercentGain["9"] = {count:dailyPercentGain["9"].count+1,dates:[...dailyPercentGain["9"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 10 && dailyPercentValue> 9) dailyPercentGain["10"] = {count:dailyPercentGain["10"].count+1,dates:[...dailyPercentGain["10"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 11 && dailyPercentValue> 10) dailyPercentGain["11"] = {count:dailyPercentGain["11"].count+1,dates:[...dailyPercentGain["11"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 12 && dailyPercentValue> 11) dailyPercentGain["12"] = {count:dailyPercentGain["12"].count+1,dates:[...dailyPercentGain["12"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 13 && dailyPercentValue> 12) dailyPercentGain["13"] = {count:dailyPercentGain["13"].count+1,dates:[...dailyPercentGain["13"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 14 && dailyPercentValue> 13) dailyPercentGain["14"] = {count:dailyPercentGain["14"].count+1,dates:[...dailyPercentGain["14"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 15 && dailyPercentValue> 14) dailyPercentGain["15"] = {count:dailyPercentGain["15"].count+1,dates:[...dailyPercentGain["15"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 16 && dailyPercentValue> 15) dailyPercentGain["16"] = {count:dailyPercentGain["16"].count+1,dates:[...dailyPercentGain["16"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 17 && dailyPercentValue> 16) dailyPercentGain["17"] = {count:dailyPercentGain["17"].count+1,dates:[...dailyPercentGain["17"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 18 && dailyPercentValue> 17) dailyPercentGain["18"] = {count:dailyPercentGain["18"].count+1,dates:[...dailyPercentGain["18"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 19 && dailyPercentValue> 18) dailyPercentGain["19"] = {count:dailyPercentGain["19"].count+1,dates:[...dailyPercentGain["19"].dates,{date:ele.date,type:"gain"}]}
          else if(dailyPercentValue <= 20 && dailyPercentValue> 19) dailyPercentGain["20"] = {count:dailyPercentGain["20"].count+1,dates:[...dailyPercentGain["20"].dates,{date:ele.date,type:"gain"}]}
          else{dailyPercentGain["other"] = {count:dailyPercentGain["other"].count+1,dates:[...dailyPercentGain["other"].dates,{date:ele.date,type:"gain"}]};}
          } else {
            // red candle
            dataAllCandle.push({candle:'red',date:ele.date});
            redcandleCount++;
            // calculate highest low percent gain
            const perLoss = (ele.open_price - ele.low) / (ele.open_price / 100);
             if(highestPerLossDay.percent < perLoss){
              highestPerLossDay.percent = perLoss;
              highestPerLossDay.date = ele.date;
             }
             // add daily percentage loss days count data
          let dailyPercentValue = perLoss;
          if(dailyPercentValue <= 0.5) dailyPercentLoss["0.5"] = {count:dailyPercentLoss["0.5"].count+1,dates:[...dailyPercentLoss["0.5"].dates,{date:ele.date,type:"loss"}]};
          else if(dailyPercentValue <= 1 && dailyPercentValue> 0.5) dailyPercentLoss["1"] = {count:dailyPercentLoss["1"].count+1,dates:[...dailyPercentLoss["1"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 1.5 && dailyPercentValue > 1) dailyPercentLoss["1.5"] = {count:dailyPercentLoss["1.5"].count+1,dates:[...dailyPercentLoss["1.5"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 2 && dailyPercentValue> 1.5) dailyPercentLoss["2"] = {count:dailyPercentLoss["2"].count+1,dates:[...dailyPercentLoss["2"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 3 && dailyPercentValue> 2) dailyPercentLoss["3"] = {count:dailyPercentLoss["3"].count+1,dates:[...dailyPercentLoss["3"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 4 && dailyPercentValue> 3) dailyPercentLoss["4"] = {count:dailyPercentLoss["4"].count+1,dates:[...dailyPercentLoss["4"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 5 && dailyPercentValue> 4) dailyPercentLoss["5"] = {count:dailyPercentLoss["5"].count+1,dates:[...dailyPercentLoss["5"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 6 && dailyPercentValue> 5) dailyPercentLoss["6"] = {count:dailyPercentLoss["6"].count+1,dates:[...dailyPercentLoss["6"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 7 && dailyPercentValue> 6) dailyPercentLoss["7"] = {count:dailyPercentLoss["7"].count+1,dates:[...dailyPercentLoss["7"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 8 && dailyPercentValue> 7) dailyPercentLoss["8"] = {count:dailyPercentLoss["8"].count+1,dates:[...dailyPercentLoss["8"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 9 && dailyPercentValue> 8) dailyPercentLoss["9"] = {count:dailyPercentLoss["9"].count+1,dates:[...dailyPercentLoss["9"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 10 && dailyPercentValue> 9) dailyPercentLoss["10"] = {count:dailyPercentLoss["10"].count+1,dates:[...dailyPercentLoss["10"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 11 && dailyPercentValue> 10) dailyPercentLoss["11"] = {count:dailyPercentLoss["11"].count+1,dates:[...dailyPercentLoss["11"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 12 && dailyPercentValue> 11) dailyPercentLoss["12"] = {count:dailyPercentLoss["12"].count+1,dates:[...dailyPercentLoss["12"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 13 && dailyPercentValue> 12) dailyPercentLoss["13"] = {count:dailyPercentLoss["13"].count+1,dates:[...dailyPercentLoss["13"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 14 && dailyPercentValue> 13) dailyPercentLoss["14"] = {count:dailyPercentLoss["14"].count+1,dates:[...dailyPercentLoss["14"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 15 && dailyPercentValue> 14) dailyPercentLoss["15"] = {count:dailyPercentLoss["15"].count+1,dates:[...dailyPercentLoss["15"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 16 && dailyPercentValue> 15) dailyPercentLoss["16"] = {count:dailyPercentLoss["16"].count+1,dates:[...dailyPercentLoss["16"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 17 && dailyPercentValue> 16) dailyPercentLoss["17"] = {count:dailyPercentLoss["17"].count+1,dates:[...dailyPercentLoss["17"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 18 && dailyPercentValue> 17) dailyPercentLoss["18"] = {count:dailyPercentLoss["18"].count+1,dates:[...dailyPercentLoss["18"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 19 && dailyPercentValue> 18) dailyPercentLoss["19"] = {count:dailyPercentLoss["19"].count+1,dates:[...dailyPercentLoss["19"].dates,{date:ele.date,type:"loss"}]}
          else if(dailyPercentValue <= 20 && dailyPercentValue> 19) dailyPercentLoss["20"] = {count:dailyPercentLoss["20"].count+1,dates:[...dailyPercentLoss["20"].dates,{date:ele.date,type:"loss"}]}
          else{dailyPercentLoss["other"] = {count:dailyPercentLoss["other"].count+1,dates:[...dailyPercentLoss["other"].dates,{date:ele.date,type:"loss"}]};}
          }
         })

        //  find the no of consecutive days share goes up and down
        let count = [];
        for(let i=0;i<dataAllCandle.length; i++){
        if(count.length === 0)
        {
          count.push(dataAllCandle[i]);
        }
        else{
          let lastele = count[count.length-1];
          if(dataAllCandle[i].candle === lastele.candle){
            count.push(dataAllCandle[i]);
              // if it is last element
          if(i === dataAllCandle.length-1){
            let lastele = count[count.length-1];
            dayscount[`${count.length}`][`${lastele.candle}`]= dayscount[`${count.length}`][`${lastele.candle}`]+ 1;
            dayscount[`${count.length}`]["dates"] = [...dayscount[`${count.length}`]["dates"],{start:count[0].date,end:lastele.date,candle:lastele.candle}]
          }
          }else{
            dayscount[`${count.length}`][`${lastele.candle}`]= dayscount[`${count.length}`][`${lastele.candle}`]+ 1;
            dayscount[`${count.length}`]["dates"] = [...dayscount[`${count.length}`]["dates"],{start:count[0].date,end:lastele.date,candle:lastele.candle}]
            count = [];
            count.push(dataAllCandle[i]); 

          // if it is last element
            if(i === dataAllCandle.length-1){
              let lastele = count[count.length-1];
              dayscount[`${count.length}`][`${lastele.candle}`]= dayscount[`${count.length}`][`${lastele.candle}`]+ 1;
              dayscount[`${count.length}`]["dates"] = [...dayscount[`${count.length}`]["dates"],{start:count[0].date,end:lastele.date,candle:lastele.candle}]
            }
          }
        }
        }
        const dailyPercentMonthlyList = MergePerObject(dailyPercentGain,dailyPercentLoss);
        const consecutiveGainLossDaysList = ObjectToKeyValueArr(dayscount)
        const totalPerGainInMonth = (highestPriceInMonth.highPrice-firstDayOpenPrice)/(firstDayOpenPrice/100)
        const totalPerLossInMonth = (firstDayOpenPrice-lowestPriceInMonth.lowPrice)/(firstDayOpenPrice/100)

         const d= {
          ...eachMonth,
          redcandleCount,
          greenCandleCount,
          highestPerGainDay,
          highestPerLossDay,
          firstDayOpenPrice,
          lastDayClosePrice,
          highestPriceInMonth,
          lowestPriceInMonth,
          totalPerGainInMonth,
          totalPerLossInMonth,
          dailyPercentMonthlyList,
          consecutiveGainLossDaysList
        };
        FinalSingleFileData.push(d);

      })
      AllFileData.push(FinalSingleFileData);
     });
     
  
     setAllData(AllFileData)
   }, [DATA])

   const getMonthName = (date) => {
    let monthKey = date.split("-")[1];
    return month[monthKey] + " " + date.split("-")[0]
  }

const handleSelectMonth = (e) => {
  let data = [];
   allData.forEach(eachFile => {
       const d = eachFile.filter(ele => ele.month.split("-")[1] === e.target.value);
       data =[...data,...d]
   })
   setSelectedMonthsData(data);
   setSelectedMonth(e.target.value)

  //   calculate results based on monthly data
      const totalPerGain = data.reduce((prev,curr) => prev+curr.totalPerGainInMonth,0);
      const totalPerLoss = data.reduce((prev,curr) => prev+curr.totalPerLossInMonth,0);
      const totalPerGainAvg = totalPerGain/allData.length
      const totalPerLossAvg = totalPerLoss/allData.length
  
      // Number('2023-01-02'.split("-")[2])
      // below arr conatin all week number that gives high percent gain and loss
      const HighPerGainWeeksArr = [];
      const HighPerLossWeeksArr = [];
      const HighPriceGainWeeksArr = []
      const HighPriceLossWeeksArr = []

      data.forEach(item => {
        const monthData = item.data;
        const gainPerWeekNo = getWeekNumber(item.highestPerGainDay.date)
        const LossPerWeekNo = getWeekNumber(item.highestPerLossDay.date)
        const gainPriceWeekNo = getWeekNumber(item.highestPriceInMonth.date)
        const LossPriceWeekNo = getWeekNumber(item.lowestPriceInMonth.date)

        HighPerGainWeeksArr.push(gainPerWeekNo);
        HighPerLossWeeksArr.push(LossPerWeekNo);
        HighPriceGainWeeksArr.push(gainPriceWeekNo);
        HighPriceLossWeeksArr.push(LossPriceWeekNo);
     
       
        // monthData.forEach(ele => {
        //     // week 1 analysis
        //     if(Number(ele.date.split("-")[2]) <= 7 ){
        //     }
        //     // week 2
        //     else if(Number(ele.date.split("-")[2]) <= 14 && Number(ele.date.split("-")[2]) > 7){
              
        //     }
        //     // week 3
        //     else if(Number(ele.date.split("-")[2]) <= 21 && Number(ele.date.split("-")[2]) > 14){
              
        //     }
        //     //  week 4
        //     else if(Number(ele.date.split("-")[2]) <= 31 && Number(ele.date.split("-")[2]) > 21){

        //     }
        // })
      })

      const HighestRepeatingPerGainWeek = getMostRepeatingArrayEle(HighPerGainWeeksArr)
      const HighestRepeatingPerLossWeek = getMostRepeatingArrayEle(HighPerLossWeeksArr)
      const HighestRepeatingPriceGainWeek = getMostRepeatingArrayEle(HighPriceGainWeeksArr)
      const HighestRepeatingPriceLossWeek = getMostRepeatingArrayEle(HighPriceLossWeeksArr)
      
      const analysysData = {
          perGainWeek:HighestRepeatingPerGainWeek,
          perLossWeek:HighestRepeatingPerLossWeek,
          PriceGainWeek:HighestRepeatingPriceGainWeek,
          PriceLossWeek:HighestRepeatingPriceLossWeek,
      }

      setNote(analysysData)
     
    }

    const handleshowDates = (ele,percent) => {
      if(percent){
        setHoveredCandleDates(ele.dates);
      }else{
        setHoveredCandleDates(ele.value.dates);
      }
       setShowCandleDatesId(ele.id)
    } 

  const handleLeaveMouseonCandle = () => {
    setShowCandleDatesId("");
    setHoveredCandleDates([])
  }
  return (
    <>
      <div className='PercentAnalysis'>
          <div className='display-flex1'>
            <span className="material-icons close-analysis" onClick={() => setShowMontlyAnalysis(false)}>close</span>
            <div className='month-analysis-header-cont'>
                       <h2> Monthly Analysis</h2>
                       <div>
                       <select name="number" placeholder='Select Month' className='month-list-select' value={selectedMonth}  onChange={handleSelectMonth}>
        <option value=""  >Select Month</option>
        {Object.keys(month).sort((a,b) => a > b ? 1 : -1).map(ele => {
            return(
          <option key={ele} value={ele}>{month[ele]}</option>
            )
        }) }
         
        </select>
        {selectedMonth && <button className='month-analysis-btn' onClick={() => setShowAddNotePopup(true)}>Add Note</button>}
        {showAddNotePopup && 
        <MonthlyAnalysisNote
        close = {setShowAddNotePopup}
        note={note}
        handleAddNote={handleAddNote}
        />
        }
                       </div>
                      </div>

                      {/* if no symbol selected then complete files data will be visible */}
         { !selectedMonth ?  <div className="monthlyDataContainer">
              {
                allData.map((singleFile,index) =>{
                  return(
                    <div key={`sigglefiledata${index*23}`} className='single-file-month-data'>
                    <h4 style={{padding:"10px 0px"}}>{ALLFILENAMES[index]}</h4>
                    <div className='all-month-cont'>
                    {
                      singleFile.map((ele,i) => {
                         return(
                          <div className='month-detail-box'>

                          <div className='month-info-label'>{getMonthName(ele.month)}</div>
                         <div> 
                          <div className='month-info-label'>First Day Open Price - </div> 
                          <span className="month-info-value">{ele.firstDayOpenPrice}</span> 
                          </div>

                         <div> 
                          <div className='month-info-label'>Last Day Close Price - </div> 
                          <span className="month-info-value">{ele.lastDayClosePrice}</span> 
                          </div>
   
                         <div> 
                          <div className='month-info-label'>greenCandleCount </div>
                          <span className="month-info-value">{ele.greenCandleCount}</span>
                          </div>

                         <div> 
                          <div className='month-info-label'>redcandleCount </div>
                          <span className="month-info-value">{ele.redcandleCount}</span>
                          </div>

                         <div> 
                          <div className='month-info-label'>totalPerGainInMonth </div>
                          <span className="month-info-value">{ele.totalPerGainInMonth.toFixed(2)}</span>
                          </div>

                         <div>
                          <div className='month-info-label'>totalPerLossInMonth </div>
                          <span className="month-info-value">{ele.totalPerLossInMonth.toFixed(2)}</span>
                          </div>

                         <div> 
                          <div className='month-info-label'>highestPriceInMonth</div>
                          <div>  <span className="month-info-value">{ele.highestPriceInMonth.highPrice}</span> <span className='mon-date'>{ele.highestPriceInMonth.date}</span> </div>
                          </div>

                         <div> <div className='month-info-label'>lowestPriceInMonth </div>
                         <div> <span className="month-info-value">{ele.lowestPriceInMonth.lowPrice}</span> <span className='mon-date'>{ele.lowestPriceInMonth.date}</span> </div>
                         </div>

                         <div>
                          <div className='month-info-label'>highestPerGainDay  </div>
                          <div> <span className="month-info-value">{ele.highestPerGainDay.percent.toFixed(2)}</span> <span className='mon-date'>{ele.highestPerGainDay.date}</span> </div>
                          </div>

                         <div> 
                          <div className='month-info-label'>highestPerLossDay  </div>
                          <div> <span className="month-info-value">{ele.highestPerLossDay.percent.toFixed(2)}</span> <span className='mon-date'>{ele.highestPerLossDay.date}</span> </div>
                          </div>

                         <div>
                         <table className='montly-analysis-table'>
                  <thead>
                    <tr >
                      <th >Days</th>
                      <th >Green</th>
                      <th >Red</th>
                    </tr>
                  </thead >
                  <tbody >
                    {ele.consecutiveGainLossDaysList.map((ele,i) => {
                      if(!ele.value.green && !ele.value.red){return}
                      return(
                     <>
                        <tr className='candle-list-table' key={`petable${i}`} id={ele.id} style={{position:'relative'}} onMouseEnter={() => handleshowDates(ele)} onMouseLeave={handleLeaveMouseonCandle}  >
                        <td className="">{ele.key}</td>
                        <td className="color-green">{ele.value.green}</td>
                        <td className="color-red">{ele.value.red}</td>
                  {showCandleDatesId == ele.id &&  <div className='month-analysis-hover-dates' id={ele.id}>{
          hoveredCandleDates.map(ele => {
            return(
              <>
               <span style={{width:"65px",display:"inline-block",marginTop:"5px"}}>{ele.candle}</span>
               {`start - ${ele.start} end - ${ele.end}`}
               <br />
              </>
            )
          })
        }</div>}
                        </tr>
        </>
                      )
                    })}
                   
                    </tbody>
                    </table>
                    <table className='montly-analysis-table'>
                  <thead>
                    <tr >
                      <th >Per</th>
                      <th > gcount</th>
                      <th >rcount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ele.dailyPercentMonthlyList.map((ele,i) => {
                      if(ele.percentGainCount || ele.percentLossCount)
                      return(
                        <tr key={`pertable${i}`} id={ele.id} style={{position:'relative'}} onMouseEnter={() => handleshowDates(ele,true)} onMouseLeave={handleLeaveMouseonCandle}>
                        <td className="">{ele.day}</td>
                        <td className="color-green">{ele.percentGainCount}</td>
                        <td className="color-red">{ele.percentLossCount}</td>
                        {showCandleDatesId == ele.id &&  <div className='month-analysis-hover-dates' id={ele.id}>{
             hoveredCandleDates.map(el => {
              return(
                <>
                 <span style={{width:"65px",display:"inline-block",marginTop:"5px"}}>{el.type}</span>
                 {`date - ${el.date}`}
                 <br />
                </>
              )
             })
           }</div>}
                      </tr>
                      )
                    })}
                    </tbody>
                    </table>
                         </div>

                          </div >
                         )
                      })
                    }
                    </div>
                    </div>
                  )

                })
              }
            </div> 
            :
            // if there is selected months then  show only specific month data
            <div className="monthlyDataContainer">
                       <div className='all-month-cont'>
                    {
                      selectedMonthsData.map((ele,i) => {
                         return(
                          <div className='month-detail-box'>

                          <div className='month-info-label'>{getMonthName(ele.month)}</div>
                         <div> 
                          <div className='month-info-label'>First Day Open Price - </div> 
                          <span className="month-info-value">{ele.firstDayOpenPrice}</span> 
                          </div>

                          <div> 
                          <div className='month-info-label'>Last Day Close Price - </div> 
                          <span className="month-info-value">{ele.lastDayClosePrice}</span> 
                          </div>
   
                         <div> 
                          <div className='month-info-label'>greenCandleCount </div>
                          <span className="month-info-value color-green">{ele.greenCandleCount}</span>
                          </div>

                         <div> 
                          <div className='month-info-label'>redcandleCount </div>
                          <span className="month-info-value color-red">{ele.redcandleCount}</span>
                          </div>

                         <div> 
                          <div className='month-info-label'>totalPerGainInMonth </div>
                          <span className="month-info-value color-green">{ele.totalPerGainInMonth.toFixed(2)}</span>
                          </div>

                         <div>
                          <div className='month-info-label'>totalPerLossInMonth </div>
                          <span className="month-info-value color-red">{ele.totalPerLossInMonth.toFixed(2)}</span>
                          </div>

                         <div> 
                          <div className='month-info-label'>highestPriceInMonth</div>
                          <div>  <span className="month-info-value">{ele.highestPriceInMonth.highPrice}</span> <span className='mon-date'>{ele.highestPriceInMonth.date}</span> </div>
                          </div>

                         <div> <div className='month-info-label'>lowestPriceInMonth </div>
                         <div> <span className="month-info-value">{ele.lowestPriceInMonth.lowPrice}</span> <span className='mon-date'>{ele.lowestPriceInMonth.date}</span> </div>
                         </div>

                         <div>
                          <div className='month-info-label'>highestPerGainDay  </div>
                          <div> <span className="month-info-value color-green">{ele.highestPerGainDay.percent.toFixed(2)}</span> <span className='mon-date'>{ele.highestPerGainDay.date}</span> </div>
                          </div>

                         <div> 
                          <div className='month-info-label'>highestPerLossDay  </div>
                          <div> <span className="month-info-value color-red">{ele.highestPerLossDay.percent.toFixed(2)}</span> <span className='mon-date'>{ele.highestPerLossDay.date}</span> </div>
                          </div>

                          <div>
                         <table className='montly-analysis-table'>
                  <thead>
                    <tr >
                      <th >Days</th>
                      <th >Green</th>
                      <th >Red</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ele.consecutiveGainLossDaysList.map((ele,i) => {
                      if(ele.value.green || ele.value.red)
                      return(
                        <>
                           <tr className='candle-list-table' key={`petable${i}`} id={ele.id} style={{position:'relative'}} onMouseEnter={() => handleshowDates(ele)} onMouseLeave={handleLeaveMouseonCandle}  >
                           <td className="">{ele.key}</td>
                           <td className="color-green">{ele.value.green}</td>
                           <td className="color-red">{ele.value.red}</td>
                     {showCandleDatesId == ele.id &&  <div className='month-analysis-hover-dates' id={ele.id}>{
             hoveredCandleDates.map(ele => {
                return(
                  <>
                   <span style={{width:"65px",display:"inline-block",marginTop:"5px"}}>{ele.candle}</span>
                   {`start - ${ele.start} end - ${ele.end}`}
                   <br />
                  </>
                )
             })
           }</div>}
                           </tr>
           </>
                         )
                    })}
                    </tbody>
                    </table>
                    <table className='montly-analysis-table'>
                  <thead>
                    <tr >
                      <th >Per</th>
                      <th > gcount</th>
                      <th >rcount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ele.dailyPercentMonthlyList.map((ele,i) => {
                      if(ele.percentGainCount || ele.percentLossCount)
                      return(
                        <tr key={`pertable${i}`} id={ele.id} style={{position:'relative'}} onMouseEnter={() => handleshowDates(ele,true)} onMouseLeave={handleLeaveMouseonCandle}>
                        <td className="">{ele.day}</td>
                        <td className="color-green">{ele.percentGainCount}</td>
                        <td className="color-red">{ele.percentLossCount}</td>
                        {showCandleDatesId == ele.id &&  <div className='month-analysis-hover-dates' id={ele.id}>{
             hoveredCandleDates.map(ele => {
              return(
                <>
                 <span style={{width:"65px",display:"inline-block",marginTop:"5px"}}>{ele.type}</span>
                 {`date - ${ele.date}`}
                 <br />
                </>
              )
             })
           }</div>}
                      </tr>
                      )
                    })}
                    </tbody>
                    </table>
                         </div>

                          </div >
                         )
                      })
                    }
                    </div>
              </div>
            }
        
           
           
          

         
          </div>
      </div>
    </>
  )
}

export default memo(MontlyAnalysis)