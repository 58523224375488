import React, { useState } from 'react'
import './PortFolioTrend.css'
import { useAlert } from 'react-alert';
import { IoCloudUpload } from "react-icons/io5";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Papa from "papaparse";
import { addPortfolio, getAIMatrixData } from '../../Actions/Matrix';
import { useEffect } from 'react';
import { handleTrendFilter } from '../../Actions/StaticFunction';


const PortFolioTrend = ({ setTrendAnalysisOpen }) => {

    const [file, setFile] = useState(null);
    const [funds, setFunds] = useState("");
    const [userHolding, setUserHolding] = useState([]);
    const [showAverageTable, setShowAverageTable] = useState(false);
    const [loading,setLoading] = useState(false);
    const [name,setName] = useState("");
    const [availableFunds,setAvalableFunds] = useState("");
    const alert = useAlert();
    const headers = ["Instrument", "Qty", "Avg cost", "LTP", "Cur val", "P&L", "Net chg", "Day chg","Trend"]
    

    const getData = async(updatedData) => {
        // const startDate = new Date("2024-05-10");
        const startDate = new Date();
        setLoading(true);
        let holdingsData = [...updatedData];
        let data = {
          trend:"positive",
          pattern_type:"weekly",
          startDate,
        }
         const res = await getAIMatrixData(data);
       if(res.success){
        if(res.data.length === 0){
        //  setMessage("No Data Found");
        }else{
          holdingsData =  holdingsData.map(symbol => {
              let asd = symbol;
              
                  res.data.forEach(item => {
                    if(item.matchSybols){
                  let record = item.matchSybols.find(ele => ele.singleSymbol === symbol.symbol); 
                      if(record){
                      const isInclude = handleTrendFilter(record.resultButttonData,record.positive)
                      if(isInclude){
                          asd = {...asd,trend:asd.trend ? [...asd.trend,{symbol:record.singleSymbol,positive:record.positive,type:"WP"}]:[{symbol:record.singleSymbol,positive:record.positive,type:"WP"}]}     
                      }
                      }
                    }
                  })
               return asd;
            })
        }
       }
    //    else{
    
    //    }

    let data1 = {
        trend:"negative",
        pattern_type:"weekly",
        startDate,
      }

      const res1 = await getAIMatrixData(data1);
      if(res1.success){
       if(res1.data.length === 0){
       //  setMessage("No Data Found");
       }else{
         holdingsData =  holdingsData.map(symbol => {
             let asd = symbol;
             
                 res1.data.forEach(item => {
                   if(item.matchSybols){
                 let record = item.matchSybols.find(ele => ele.singleSymbol === symbol.symbol); 
                     if(record){
                     const isInclude = handleTrendFilter(record.resultButttonData,record.positive)
                     if(isInclude){
                         asd = {...asd,trend:asd.trend ? [...asd.trend,{symbol:record.singleSymbol,positive:record.positive,type:"WN"}]:[{symbol:record.singleSymbol,positive:record.positive,type:"WN"}]}     
                     }
                     }
                   }
                 })
              return asd;
           })
       }
      }

    
      let data2 = {
        trend:"positive",
        pattern_type:"daily",
        startDate,
      }

      const res2 = await getAIMatrixData(data2);
      if(res2.success){
       if(res2.data.length === 0){
       //  setMessage("No Data Found");
       }else{
         holdingsData =  holdingsData.map(symbol => {
             let asd = symbol;
             
                 res2.data.forEach(item => {
                   if(item.matchSybols){
                 let record = item.matchSybols.find(ele => ele.singleSymbol === symbol.symbol); 
                     if(record){
                     const isInclude = handleTrendFilter(record.resultButttonData,record.positive,true);
                     if(isInclude){
                         asd = {...asd,trend:asd.trend ? [...asd.trend,{symbol:record.singleSymbol,positive:record.positive,type:"DP"}]:[{symbol:record.singleSymbol,positive:record.positive,type:"DP"}]}     
                     }
                     }
                   }
                 })
              return asd;
           })
       }
      }


      let data3 = {
        trend:"negative",
        pattern_type:"daily",
        startDate,
      }

      const res3 = await getAIMatrixData(data3);
      if(res3.success){
       if(res3.data.length === 0){
       //  setMessage("No Data Found");
       }else{
         holdingsData =  holdingsData.map(symbol => {
             let asd = symbol;
             
                 res3.data.forEach(item => {
                   if(item.matchSybols){
                 let record = item.matchSybols.find(ele => ele.singleSymbol === symbol.symbol); 
                     if(record){
                     const isInclude = handleTrendFilter(record.resultButttonData,record.positive,true)
                     if(isInclude){
                         asd = {...asd,trend:asd.trend ? [...asd.trend,{symbol:record.singleSymbol,positive:record.positive,type:"DN"}]:[{symbol:record.singleSymbol,positive:record.positive,type:"DN"}]}     
                     }
                     }
                   }
                 })
              return asd;
           })
       }
      }

       setLoading(false);
    setUserHolding(holdingsData)
    setShowAverageTable(true);
      }


    //   useEffect(() => {
    // //    getData();
    //   },[])
  

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const handleParse = () => {
        if (!file) {
            alert.error("Please select the file first");
            return;
        }
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                let parsedData = results.data;

                const updatedData = [];
                parsedData.forEach(ele => {
                    const symbol = ele["Instrument"];
                    const quantity = Number(ele["Qty."]);
                    const avgCost = Number(ele['Avg. cost']);
                    const currentPrice = Number(ele['LTP'])
                    const currentValue = Number(ele["Cur. val"])
                    const profitLoss = Number(ele["P&L"])
                    const netCharge = Number(ele["Net chg."]);
                    const dayCharge = Number(ele["Day chg."]);

                    let data = { symbol, quantity, avgCost, currentPrice, currentValue, profitLoss, netCharge, dayCharge };
                    updatedData.push(data);
                })
                // updatedData.sort((a, b) => b.percentDown - a.percentDown);


                // setUserHolding(updatedData);
                getData(updatedData);
                
            },
        });
    }


    const handleBrokingSelect = (value) => {
    }

    const handleSavePortfolio = async() => {
        if(!name || !availableFunds || !userHolding || userHolding.length === 0){
            alert.error("Please upload valid data");
            return;
        }
        const data = {
            name,
            availableFunds:availableFunds? Number(availableFunds):"",
            portfolio:userHolding,
        };
        const res = await addPortfolio(data);
        if(res.success){
           alert.success(res.message);
         }else{
            alert.error(res.message);
         }
    }

   

    return (
        <div className='portfolio-container' >
                <span className="material-icons close-calc" onClick={() => setTrendAnalysisOpen(false)}>close</span>
    
            <div className=''>
                <div className="row">

                    <div className='upload-portfolio'>
                        <label htmlFor="csvInput">
                            <span className='me-3'>Upload Your Investment Portfolio</span>
                            {/* <img src="./images/uploadfile.jpg" alt=""  className='upload-file-icon'/> */}
                            <IoCloudUpload
                                color='blue'
                                fontSize={"32px"}
                            />
                        </label>
                        <input
                            className="file-input ms-3"
                            onChange={handleFileChange}
                            id="csvInput"
                            accept=".csv"
                            name="file"
                            type="File"
                            style={{ display: "none" }}
                        // multiple
                        />

                        <div className="buttons">

                            <button onClick={handleParse} className='upload-botton btn btn-light' style={{ marginBottom: '5px' }}>Upload</button>
                        </div>

                        <div>
                      <input 
                      type='input'  
                      value={name} 
                      onChange={(e) => setName(e.target.value)} 
                      className='port-uname' 
                      placeholder='User name'
                      />
                        </div>
                        <div>
                      <input 
                      type='number'  
                      value={availableFunds} 
                      onChange={(e) => setAvalableFunds(e.target.value)} 
                      className='port-uname' 
                      placeholder='Available funds'
                      />
                        </div>

                        <div>
                            <FormControl sx={{ m: 0, minWidth: 200 }} size="small">
                                <InputLabel id="demo-select-small-label">Broker</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    // value={age}
                                    label="Broker"
                                    onChange={handleBrokingSelect}
                                >
                                    <MenuItem value={"Zerodha"} sx={{ padding: '8px 16px' }}>Zerodha</MenuItem>
                                    <MenuItem value={"Angel One"} sx={{ padding: '8px 16px' }}>Angel One</MenuItem>
                                    <MenuItem value={"Upstock"} sx={{ padding: '8px 16px' }}>Upstock</MenuItem>
                                    <MenuItem value={"Upstock"} sx={{ padding: '8px 16px' }}>Grow</MenuItem>


                                </Select>
                            </FormControl>
                        </div>

                    </div>
                     {loading && <div>Loading...</div>}
                    {showAverageTable &&
                        <TableContainer component={Paper} className='portfolio-table'>
                            <Table sx={{ minWidth: 850 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                               {
                                            headers.length > 0 && headers.map(ele => {
                                                return (
                                                    <TableCell
                                                    sx={{border:"1px solid rgba(224, 224, 224, 1)"}}
                                                    >{ele}</TableCell>
                                                )

                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userHolding.map((row) => (
                                        <TableRow
                                            key={row.currentPrice}
                                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.symbol}
                                            </TableCell>
                                            <TableCell >{row.quantity}</TableCell>
                                            <TableCell >{row.avgCost}</TableCell>
                                            <TableCell >{row.currentPrice}</TableCell>
                                            <TableCell >{row.currentValue}</TableCell>
                                            <TableCell sx={row.profitLoss > 0 ? { color: "green" } : row.profitLoss < 0 ? { color: 'red' } : { color: "black" }} >{row.profitLoss}</TableCell>
                                            <TableCell sx={row.netCharge > 0 ? { color: "green" } : row.netCharge < 0 ? { color: 'red' } : { color: "black" }}>{row.netCharge}</TableCell>
                                            <TableCell sx={row.dayCharge > 0 ? { color: "green" } : row.dayCharge < 0 ? { color: 'red' } : { color: "black" }}>{row.dayCharge}</TableCell>
                                            <TableCell>{
                                            row?.trend?.length > 0 ? row.trend.map(ele => 
                                               { return (
                                                    <span style={{marginRight:"3px"}} className={ele.positive ? "avggreen": ele.positive === false ? "avgred":""}>{ele.type}</span>
                                            )}) : "-" 
                                            }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {showAverageTable && <button className='save-port-btn' onClick={handleSavePortfolio}>Save</button>}

                </div>
            </div>



        </div>

    )
}


export default PortFolioTrend