import React, { useEffect, useState } from 'react'
import { memo } from "react";
import "./AllPages.css"
import { useAlert } from 'react-alert';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { deletePortfolio, getPortfolio } from '../Actions/Matrix';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BuySellNoticationModal from '../Models/portfolio/BuySellNotification/BuySellNotificationModal';
import { sendBuySellNotification } from '../Actions/BuySell';


const PortFolioList = () => {
  const [portfolioList,setPortfolioList] = useState([]);
  const alert = useAlert();
  const [loading,setLoading] = useState(true);
  const { blurActive } = useSelector(state => state.SidebarDate);
  const [showSendNotificationPopup, setShowSendNotificationPopup] = useState(false);
  const [selectedSymbol,setSelectedSymbol] = useState("")

  const headers = ["Instrument", "Qty", "Avg cost", "LTP", "Cur val", "P&L", "Net chg", "Day chg","Trend"]
  
useEffect(() => {
  getData();
},[]);

const getData = async () => {
    setLoading(true);
    const res = await getPortfolio();
    setLoading(false);
    if(res.success){
    setPortfolioList(res.data);
}else{
//    handle error
}
}


const handleDeletePortfolio = async (id) => {
    const res = await deletePortfolio(id);
    if(res.success){
      alert.success(res.message);
      getData();
    }else{
     alert.error(res.message);
    }
}

const handleOpenNotificationPopup = (companyName) => {
  setShowSendNotificationPopup(true);
  setSelectedSymbol(companyName);
};

const handleCloseNotificationPopup = () => {
  setShowSendNotificationPopup(false);
};

const handleSendNotification = async(note, option) => {
 if(!option){
  alert.error("Please fill all option")
  return;
 }
  const data = {
    companyName:selectedSymbol,
    description:note,
    call:option,
    // userId, //need the userId who won this portfolio
        }
  // api call
  setShowSendNotificationPopup(false);
  const res = await sendBuySellNotification(data);
  console.log(res);
   if(res.success){
     alert.success(res.message || "Notification Addded successfully");
   }else{
    alert.error(res.message || "Failed to send notification");
   }
};
  
if(loading){
  return (
    <div className={blurActive ? "main-body blur" : "main-body"}> 
    <h3 className='h3'>Portfolio List</h3>
    <div className="Nodata">
    <h5>.....Loading</h5>
        </div>
    </div>
    )
}
else if(portfolioList.length > 0){
return (
  <div className={blurActive ? "main-body blur" : "main-body"}> 
  <BuySellNoticationModal
  handleClose={handleCloseNotificationPopup}
  handleSubmit={handleSendNotification}
  show={showSendNotificationPopup}
  />
 <h3 className='h3'>Portfolio List</h3>
 
    <div className='portfolio-cont'>
    
     { portfolioList.map((ele,i) => {
         return(
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              {ele.name}{"      "} (₹{ele.availableFunds ? ele.availableFunds.toLocaleString(): ele.availableFunds})
            </AccordionSummary>
            <AccordionDetails>
            <TableContainer component={Paper} className='portfolio-table'>
                            <Table sx={{ minWidth: 850 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                               {
                                            headers.length > 0 && headers.map(ele => {
                                                return (
                                                    <TableCell
                                                    sx={{border:"1px solid rgba(224, 224, 224, 1)"}}
                                                    >{ele}</TableCell>
                                                )

                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ele.data.map((row) =>
                                    {
                                      let {quantity,avgCost,currentPrice,profitLoss,netCharge,dayCharge,} = row;
                                      const currentValue = currentPrice ? quantity*currentPrice : undefined;
                                      const totalProfitLoss = currentPrice ?currentValue - (quantity*avgCost) : undefined;
                                      const totalNetCharge = currentPrice ? (currentPrice-avgCost)/(avgCost/100) : undefined;
                                      return  (
                                        <TableRow
                                            key={row.currentPrice}
                                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" onClick={() =>handleOpenNotificationPopup(row.symbol)}>
                                                {row.symbol ?? "NA"}
                                            </TableCell>
                                            <TableCell >{row.quantity ?? "NA"}</TableCell>
                                            <TableCell >{row.avgCost ?? "NA"}</TableCell>
                                            <TableCell >{row.currentPrice ?? "NA"}</TableCell>
                                            <TableCell >{currentValue?.toFixed(2) ?? "NA"}</TableCell>
                                            <TableCell sx={totalProfitLoss > 0 ? { color: "green" } : totalProfitLoss < 0 ? { color: 'red' } : { color: "black" }} >{totalProfitLoss?.toFixed(2) ?? "NA"}</TableCell>
                                            <TableCell sx={totalNetCharge > 0 ? { color: "green" } : totalNetCharge < 0 ? { color: 'red' } : { color: "black" }}>{totalNetCharge?.toFixed(2) ?? "NA"}</TableCell>
                                            <TableCell sx={row.dayCharge > 0 ? { color: "green" } : row.dayCharge < 0 ? { color: 'red' } : { color: "black" }}>{row.dayCharge?.toFixed(2) ?? "NA"}</TableCell>
                                            <TableCell>{
                                            row?.trend?.length > 0 ? row.trend.map(ele => 
                                               { return (
                                                    <span style={{marginRight:"3px"}} className={ele.positive ? "avggreen": ele.positive === false ? "avgred":""}>{ele.type}</span>
                                            )}) : "-" 
                                            }
                                            </TableCell>
                                        </TableRow>
                                    )
                                    } 
                                   )}
                                </TableBody>
                            </Table>
                        </TableContainer>
            </AccordionDetails>
            <AccordionActions>
              {/* <Button>Cancel</Button> */}
              <Button onClick={() => handleDeletePortfolio(ele._id)}>Delete</Button>
            </AccordionActions>
          </Accordion>
         )
      })
    }
    </div>

</div>
)
}else{
return (
 <div className={blurActive ? "main-body blur" : "main-body"}>  
<h3 className='h3'>Portfolio List</h3>
<div className="Nodata">
   <h2 >😮OOPS!  </h2>
   <h3>No Data available</h3>
    </div>
</div>
)
}
}

export default memo(PortFolioList);