import React, { useEffect, useState } from 'react'
import './MonthlyAnalysisNote.css'

const MonthlyAnalysisNote = ({close,note,handleAddNote}) => {
  const [data,setData] = useState(null);

  useEffect(() => {
  setData(note);
  },[note])

  const handleOnChange = (e) => {
   setData({...data,[e.target.name]:e.target.value})
  }

  return (
    <div className='mac-cont'>
      <h3>Note</h3>
      <div className='mac-content'>
        <div className='mac-content-item'>
            <span>Highest Percent Gain week</span>
           <input type="text" className='mac-input' name="perGainWeek" value={data?.perGainWeek || ""} onChange={handleOnChange}/>
        </div>
        <div className='mac-content-item'>
            <span>Highest percent Loss week</span>
           <input type="text" className='mac-input' name="perLossWeek" value={data?.perLossWeek || ""} onChange={handleOnChange}/>
        </div>
        <div className='mac-content-item'>
            <span>Lowest price week</span>
           <input type="text" className='mac-input' name="PriceGainWeek" value={data?.PriceLossWeek} onChange={handleOnChange}/>
        </div>
        <div className='mac-content-item'>
            <span>Highest price week</span>
           <input type="text" className='mac-input' name="PriceLossWeek" value={data?.PriceGainWeek} onChange={handleOnChange}/>
        </div>
        {/* <div className='mac-content-item'>
            <span>Consecutive Week Red</span>
           <input type="text" className='mac-input' name="PriceLossWeek" value={data?.PriceLossWeek} onChange={handleOnChange}/>
        </div>
        <div className='mac-content-item'>
            <span>Consecutive Week Green</span>
           <input type="text" className='mac-input' name="PriceLossWeek" value={data?.PriceLossWeek} onChange={handleOnChange}/>
           
        </div> */}
       
        <textarea name="desc" id="" cols="30" rows="4" value={data?.desc || "" } onChange={handleOnChange} className='mac--desc-textArea'></textarea>
      </div>
      <div className='mac-footer-cont'>
        <button onClick={() => close(false)}>Cancel</button>
        <button onClick={() =>handleAddNote(data)}>Save</button>
      </div>
    </div>
  )
}

export default MonthlyAnalysisNote