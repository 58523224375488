import React, { useState } from 'react'
import Select from 'react-select';
import './DataMatrixResultCompareModel.css'
import { getMatrixAnalysisDataByDateRange, getMatrixDataByDateRange, getPchnageDataByDateRange } from '../../Actions/BuySell';
import DatePicker from "react-datepicker";
import { format } from 'timeago.js';

const patterns = {
    "A": "true_false_false_true",
    "B": "true_false_false_false",
    "C": "true_false_true_false",
    "D": "true_false_true_true",
    "E": "false_false_true_true",
    "F": "false_false_true_false",
    "G": "false_false_false_false",
    "H": "false_false_false_true",
}


const DataMatrixResultCompareModel = ({ setshowCompareResult, symbolList, fromDate, toDate, isDateEqual }) => {
    const [selectedSymbols, setSelectedSymbols] = useState([]);
    const [loading, setLoading] = useState(false);
    const [comparisionData, setComparisionData] = useState([]);
    const [customSymbol,setCustomSymbol] = useState("")
    const [startDate,setStartDate] = useState(new Date())
    const [endDate,setEndDate] = useState(new Date())
    const [showCustomeDatePicker,setShowCustomeDatePicker] = useState(false);
    const [showAllresults,setShowAllResults] = useState(false);
    const [allresultsData,setAllResultsData] = useState(false);
    const [showPchnageUI,setShowPchnageUI] = useState(false);
    const [pchnageData,setPchnageData] = useState([]);
    const [allpchnageData,setAllPchnageData] = useState([]);
    const [datesList,setDateList] = useState([]);
    
   
    const handleChange = (data) => {
        // const selected = data[0]
        // setSelectedSymbols([...selectedSymbols,{...selected,fromDate,toDate}]);
        setSelectedSymbols(data)
    }
    const handleCustomSymbolChange = (value) => {
        setCustomSymbol(value);
    }

    const handleGetResult = async () => {
        setAllResultsData([]);
        setComparisionData([]);
        setPchnageData([]);
        setAllPchnageData([]);
        setDateList([])

        if (!fromDate || !toDate || isDateEqual) {
            window.alert("Please select the valid date range");
            return;
        } 
        const all = selectedSymbols.find(ele => ele.value === "all");
         if(all){
            // if all option is selected then do the api call
            setLoading(true);
            const data = {
                fromDate: fromDate,
                toDate: toDate,                
            }
            const res = await getMatrixAnalysisDataByDateRange(data);
            const res1 = await getPchnageDataByDateRange({...data,all:true})
            setLoading(false)
            if (res.success) {
                setAllResultsData(res.data);
            }
            if(res1.success){

                const symArr = res.data.map(ele => ele.symbol);
                symArr.forEach(ele => {
                    const record = res1.data.find(item => item.symbol === ele);
                    setAllPchnageData(prev => [...prev,record]);
                })
                // setAllPchnageData(res1.data);
                setDateList(res1.dateList)
            }

         }
       else if (selectedSymbols.length >= 2) {
            setLoading(true);
            const data = {
                fromDate: fromDate,
                toDate: toDate,
                symbol: selectedSymbols.map(ele => {return {symbol:ele.value,fromDate:ele?.fromDate || fromDate,toDate:ele?.toDate || toDate}} )
            }
            const res = await getMatrixDataByDateRange(data);
            setLoading(false)
            if (res.success) {
                setComparisionData(res.data);
            }
        }
        else {
            alert("Please select at least 2 symbol")
        }
    }

    const handleUpdateDate = () => {

        if(!customSymbol?.value){
            alert("Please select symbol")
        }
       const data = selectedSymbols;
       const index = selectedSymbols.findIndex(ele => ele.value === customSymbol.value);

       if(!startDate || !endDate || startDate.toDateString().slice(0,10) === endDate.toDateString().slice(0,10)){
        window.alert("Please select the valid date range");
        return;
      }
       if(index !== -1){
        data[index].fromDate = startDate.toISOString();
        data[index].toDate = endDate.toISOString();
        setSelectedSymbols(data);
        alert("set successfully")
       }
    }

    const getPchnageData = async() => {
        setAllResultsData([]);
        setComparisionData([]);
        setPchnageData([]);
        const data = {
            fromDate: fromDate,
            toDate: toDate,
            symbol: selectedSymbols[0].value
        }

        const res = await getPchnageDataByDateRange(data);
        if(res.success){
            setPchnageData(res.data)
        }
    }

    const handleCloseCustomDateChange = () => {
        setCustomSymbol("")
         setShowCustomeDatePicker(false)
         setStartDate(new Date());
         setEndDate(new Date());
    }
    return (
        <div className='compare-symbol-result-model'>
            <span className="material-icons close-analysis" onClick={() => setshowCompareResult(false)}>close</span>
            <div className="multi-symbol-container">
               {/* {showPchnageUI ? <div onClick={() => setShowPchnageUI(false)}>Pchnage</div> : <div onClick={() => setShowPchnageUI(true)}>Compare</div> } */}
                <Select
                    value={selectedSymbols}
                    onChange={handleChange}
                    options={[{label:"all",value:"all"},...symbolList]}
                    isMulti
                    className='result-symbol-select'
                />
                <button className='get-result-btn' onClick={showPchnageUI ? getPchnageData  : handleGetResult}>get results</button>
                <button className='get-result-btn' onClick={() => setShowCustomeDatePicker(true)}>custom Date</button>
            </div>
            {showCustomeDatePicker && <div className="multi-custom-date-container">
                <Select
                    value={customSymbol}
                    onChange={handleCustomSymbolChange}
                    options={selectedSymbols}
                    // isMulti
                    className='result-symbol-select'
                />
                 <div className='date-picker-div'>
          <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat='dd/MM/yyyy'
                        wrapperClassName="datamatrix-calender" 
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        // includeDates={Active}
                        // minDate={startDate}
                        maxDate={new Date()}
                        closeOnScroll={true}
                        shouldCloseOnSelect={true}
                        />
                        <span className='dash-separator'>—</span>
          <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat='dd/MM/yyyy'
                        wrapperClassName="datamatrix-calender" 
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        // includeDates={Active}
                        minDate={startDate}
                        maxDate={new Date()}
                        closeOnScroll={true}
                        shouldCloseOnSelect={true}
                        />
                        {/* <span className="material-icons arrow-getdata" onClick={handleUpdateDate}>arrow_right_alt</span> */}
          </div>
                <button className='get-result-btn' onClick={handleUpdateDate}>set date</button>
                <span className="material-icons" style={{cursor:'pointer'}} onClick={handleCloseCustomDateChange}>close</span>
            </div>
}
            {
                loading ? <div className='data-matrix-no-data'>Loading .....</div> :

                  showPchnageUI ? 
                  (pchnageData.length > 0 ? 
                   <div className='pchange-table-cont'>
                    <table className="data-matrix-result-table">
                    <thead>
                        <tr className='table-row'>
                            <th className="table-head head">Symbol</th>
                            <th className="table-head head">Date</th>
                            <th className="table-head head">pChnage</th>
                        </tr>
                    </thead>

                    <tbody>
                        {pchnageData.map((ele, i) => {
                         
                            return (
                                <tr key={`resultbutton${i}`} className='table-row'>
                                    <th className='table-head head holds-row'>
                                     { ele.symbol} 
                                    </th>
                                    <th className='table-head head holds-row'>
                                     { ele.date} 
                                    </th>
                                    <th className={`table-head head holds-row ${ele.pChnage >= 0 ? 'avggreen':'avgred' }`}>{ele.pChnage.toFixed(2)}</th>
                                </tr>
                            )
                        })
                        }
                    </tbody>

                </table>
                   </div>
                    
                    : <div className='data-matrix-no-data'> No data found</div> )


                   : <div className='comparision-result-alltable-wrapper'>
                         
                        {
                        allresultsData.length > 0 ? 
                         <>
                          <table className="data-matrix-analysis-table">
                                            <thead>
                                                <tr className='table-row'>
                                                    <th className="table-head head pchange-text">Symbol</th>
                                                    <th className="table-head head pchange-text">Count</th>
                                                    <th className="table-head head pchange-text">edcount</th>
                                                    <th className="table-head head pchange-text">gSum</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                           {allresultsData.map(ele => {
                                            return(
                                                <tr className='table-row'>
                                                    <td className='table-head head holds-row pchange-text'>{ele.symbol}</td>
                                                    <td className='table-head head holds-row pchange-text'>{ele.count}</td>
                                                    <td className='table-head head holds-row pchange-text'>{ele.ocount}</td>
                                                    <td className='table-head head holds-row pchange-text'>{ele.gsum}</td>
                                                </tr>
                                            )
                                           })}
                                            </tbody>
                                            </table>

                                            <div className='pchange-table-cont'>
                    <table className="data-matrix-result-table">
                    <thead>
                        <tr className='table-row'>
                            <th className="table-head head pchange-text">Symbol</th>
                            {datesList?.length > 0 &&  datesList.map(ele => <th  className='table-head head pchange-text'>{ele}</th>)}
                        </tr>
                    </thead>

                    <tbody>
                  {allpchnageData.length > 0 && allpchnageData.map(ele => {
                    const {symbol,EachSymbolfinalData} = ele;
                    return (
                        <tr  className='table-row'>
                            <td className='table-head head holds-row pchange-text'>{symbol}</td>
                        {EachSymbolfinalData?.length > 0 && EachSymbolfinalData.map(item => <th  className={`table-head head holds-row pchange-text ${item.pChnage >= 0 ? 'avggreen':'avgred' }`}>{item?.pChnage?.toFixed(2)}</th>)}
                       </tr>
                    )
                  })  
                 }
                    </tbody>

                </table>
                   </div>
                                            </>
                       
                        
                        : comparisionData.length > 0 ? comparisionData.map((ele, i) => {
                                const { symbol, resultButttonData } = ele;
                                return (
                                    <div className='comparison-result-table-container'>
                                        <h3 className='c-symbol'>{symbol.symbol}</h3>
                                        <p className='c-symbol'>{symbol.fromDate.slice(0,10)} to {symbol.toDate.slice(0,10)}</p>
                                        <table className="data-matrix-result-table">
                                            <thead>
                                                <tr className='table-row'>
                                                    <th className="table-head head">Pattern</th>
                                                    <th className="table-head head">Count</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {resultButttonData.map((ele, i) => {
                                                    const { key, count } = ele;
                                                    const pattern = patterns[key] ? patterns[key] : key;
                                                    return (
                                                        <tr key={`resultbutton${i}`} className='table-row'>
                                                            <th className='table-head head holds-row'>
                                                                <div className='table-data-result'>
                                                                    {
                                                                        pattern.split("_").map(ele => {
                                                                            if (ele) {
                                                                                return (
                                                                                    <span class={ele === "true" ? "material-icons avggreen" : "material-icons avgred"} style={{ fontSize: "32px" }}>
                                                                                        {ele === "true" ? "arrow_upward" : "arrow_downward"}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                    {key}
                                                                </div>
                                                            </th>
                                                            <th className='table-head head holds-row'>{count}</th>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>

                                        </table>

                                    </div>
                                )
                            })

                                : <div className='data-matrix-no-data'> No data found</div>
                        }
                    </div>
            }

        </div>

    )
}

export default DataMatrixResultCompareModel