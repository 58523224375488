import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './Holdings.css'
import { getHoldings,Addfunds, ScheduleBuyShare } from '../../Actions/BuySell';
import { useAlert } from 'react-alert'
import axios from 'axios';

const Holdings = ({ setHoldingFundsOpen }) => {
  const [activeTab, setActiveTab] = useState('holding');
  const [showAddFunds, setShowAddFunds] = useState(false);
  // const [investmentAmt,setInvestmentAmt] = useState("");
  const [symbol,setSymbol] = useState('');
  const [day,setDay] = useState("");
  const [showAddSellPercent,setShowAddSellPercent] = useState(false);
  const [showBuyCancelPopup,setShowBuyCancelPopup] = useState(false);
  const [showSellCancelPopup,setShowSellCancelPopup] = useState(false);
  const [totalInvestment,setTotalInvestment] = useState(0);
  const [id,setId] = useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const {holdings,buyShare,sellShare,funds,overallProfitPercent,profit,reports,loading} = useSelector(state => state.Data);
  const [fundAmt,setFundAmt] = useState(0);
  const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});
  
 
  useEffect(() => {
   dispatch(getHoldings());
  }, [])

  useEffect(() => {
    let investment = 0; 
    holdings.forEach(ele => investment = investment+ele.investmentAmt )
    setTotalInvestment(investment)
  },[holdings]);
  

  const addFunds = async() => {
    let obj = {
      funds:Number(fundAmt),
    }
    let data  =  await dispatch(Addfunds(obj));

    if(data.message === "Funds added successfully"){
      alert.success(data.message);
      dispatch(getHoldings());
    }
    if(data.message === 'Please enter the funds')
       alert.error(data.message);

      
  }

  const closesellPerPopup = () => {
    setShowAddSellPercent(false);
    setDay("");
  }

   const addAutoSellPercent = async() => {
   let record = holdings.filter(ele => ele.symbol === symbol);

   let obj = {
    _id:record[0]._id,
    percent:Number(day),
   }
   try {
    const {data} = await axiosInstance.post(`/autosell`,obj,{
      headers:{
        "Content-Type":"application/json",
        'x-access-token': localStorage.getItem('token')
    }
    })
    if(data.success){
      // dispatch(LoadUserNoLoad());
      dispatch(getHoldings());
         alert.success(data.message)
         closesellPerPopup();
          }
   } catch (error) {
     alert.error(error.response.data.message)
   }
   
    // dispatch(ScheduleBuyShare(obj));


   }

   const openSellPercentPopup = (symbol) => {
    setSymbol(symbol)
    setShowAddSellPercent(true);
   }

   const cancelBuyingOrder = async() => {
     let obj = {id};
    try {
      const {data} = await axiosInstance.post(`/cancel_buying`,obj,{
        headers:{
          "Content-Type":"application/json",
          'x-access-token': localStorage.getItem('token')
      }
      })
      if(data.success){
        // dispatch(LoadUserNoLoad());
           alert.success(data.message)
           closeCancelBuyingOrderModel();
           dispatch(getHoldings());
          //  closesellPerPopup();
            }
     } catch (error) {
       alert.error(error.response.data.message)
     }
     
   }

   const cancelSellingOrder = async() => {
    let obj = {id};
    try {
      const {data} = await axiosInstance.post(`/cancel_selling`,obj,{
        headers:{
          "Content-Type":"application/json",
          'x-access-token': localStorage.getItem('token')
      }
      })
      if(data.success){
           alert.success(data.message)
           closeCancelSellingOrderModel()
           dispatch(getHoldings());
            }
     } catch (error) {
       alert.error(error.response.data.message)
     }
    
   }

  const openCancelBuyingOrderModel = (id) => {
    setId(id);
    setShowBuyCancelPopup(true);
  }

  const openCancelSellingOrderModel = (id) => {
    setId(id);
    setShowSellCancelPopup(true);
  }

  const closeCancelBuyingOrderModel = (id) => {
    setId(null);
    setShowBuyCancelPopup(false);
  }

  const closeCancelSellingOrderModel = (id) => {
    setId(null);
    setShowSellCancelPopup(false);
  }

  return (
    <div className='holding-container'>
      <div className="holding-funds">
        <div className="hold_funds_nav">
          <ul>
            <li className={activeTab === 'holding' ? "funds_active" : ''} onClick={() => setActiveTab('holding')}>Holdings</li>
            <li className={activeTab === 'orders' ? "funds_active" : ''} onClick={() => setActiveTab('orders')}>Orders</li>
            <li className={activeTab === 'funds' ? "funds_active " : ''} onClick={() => setActiveTab('funds')}>Funds</li>
            <li className={activeTab === 'reports' ? "funds_active " : ''} onClick={() => setActiveTab('reports')}>Reports</li>
          </ul>
        </div>
        { loading ? <h5 className='hold-load'>.... Loading</h5> : <div className="hold_funds_body" >
          {activeTab === 'holding' ? <div className="holding">
            <h3>Holdings</h3>
            <div>
              <table className="table calc-table">
                <thead>
                  <tr className='table-row'>
                    <th className="table-head head">Company</th>
                    <th className="table-head head">Shares</th>
                    <th className="table-head head">Avg Price</th>
                    <th className="table-head head">LTP</th>
                    <th className="table-head head">Investment</th>
                    <th className="table-head head">Sell</th>

                  </tr>
                </thead>

                <tbody>
                  { holdings.map(ele => {
                    const {symbol,investmentAmt,numberOfStock,averagePrice,marketPrice} = ele;
                    return (
                      <tr key={ele._id} className='table-row '>
                    <th className="table-head head holds-row">{symbol}</th>
                    <th className="table-head head holds-row">{numberOfStock}</th>
                    <th className="table-head head holds-row">{averagePrice.toFixed(2)}</th>
                    <th className={`table-head head holds-row ${marketPrice < averagePrice?"redper":"greenper"}`}>{marketPrice}</th>
                    <th className="table-head head holds-row">{investmentAmt.toFixed(2)}</th>
                    <th className="table-head head holds-row"><span class="material-icons sell-share" onClick={() => openSellPercentPopup(symbol)}>remove</span></th>
                    </tr>
                    )
                  })
                  }
                </tbody>

              </table>
              <h4 className='total-investment'><span className="total-investment-span">Total Investment :</span>{totalInvestment}</h4>

            </div>

          </div> : activeTab === 'orders' ? <div className="holding">
            <h3>Buy</h3>
            <div>
              <table className="table calc-table">
                <thead>
                  <tr className='table-row'>
                  <th className="table-head head">Company</th>
                    <th className="table-head head">Investment</th>
                    <th className="table-head head">No of Days</th>
                    <th className="table-head head">Avg Price</th>
                    <th className="table-head head">Cancel Order</th>
                  </tr>
                </thead>

                <tbody>
                  { buyShare.map(ele => {
                    const {symbol,investmentAmt,numberOfDays,averagePrice} = ele;
                    return (
                      <tr key={ele._id} className='table-row'>
                    <th className="table-head head holds-row">{symbol}</th>
                    <th className="table-head head holds-row">{investmentAmt.toFixed(2)}</th>
                    <th className="table-head head holds-row">{numberOfDays}</th>
                    <th className="table-head head holds-row">{averagePrice.toFixed(2)}</th>
                    <th className="table-head head holds-row"><span class="material-icons sell-share" onClick={() => openCancelBuyingOrderModel(ele._id)}>remove</span></th>

                    </tr>
                    )
                  })
                  }
                </tbody>

              </table>

            </div>

            <h3>Sell</h3>
            <div>
              <table className="table calc-table">
                <thead>
                  <tr className='table-row'>
                  <th className="table-head head">Company</th>
                    <th className="table-head head">Shares</th>
                    <th className="table-head head">Trigger Per</th>
                    <th className="table-head head">LTP</th>
                    <th className="table-head head">Trigger price</th>
                    <th className="table-head head">Investment</th>
                    <th className="table-head head">Sell</th>
                  </tr>
                </thead>

                <tbody>
                { sellShare.map(ele => {
                    const {symbol,investmentAmt,numberOfStock,averagePrice,percentGain,marketPrice} = ele;
                   let triggerPrice = (averagePrice/100)*(percentGain+100)
                    return (
                      <tr key={ele._id} className='table-row '>
                    <th className="table-head head holds-row">{symbol}</th>
                    <th className="table-head head holds-row">{numberOfStock}</th>
                    <th className="table-head head holds-row">{percentGain && `${percentGain} %`}</th>
                    <th className="table-head head holds-row redper">{marketPrice}</th>
                    <th className="table-head head holds-row greenper">{triggerPrice.toFixed(2)}</th>
                    <th className="table-head head holds-row">{investmentAmt.toFixed(2)}</th>
                    <th className="table-head head holds-row"><span class="material-icons sell-share" onClick={() => openCancelSellingOrderModel(ele._id)}>remove</span></th>
                    </tr>
                    )
                  })
                  }
                </tbody>

              </table>

            </div>

          </div> : activeTab === 'funds' ?
            <div className="funds">
              <h3>Funds</h3>
              <h4><span>Available Funds :</span>  {funds.toFixed(2)}</h4>
              <h4><span>Overall Profit Gain :</span>{profit && profit.toFixed(2)}<span style={{margin:'0px'}}></span></h4>
              {!showAddFunds ? <button className='hf-btn' onClick={() => setShowAddFunds(true)}>Add funds</button>
                : <div className="addfunds_form">
                  <input type="number" placeholder='Fund Amount' value={fundAmt} onChange={(e) => setFundAmt(e.target.value)} />
                  <div>
                    <button className='hf-btn' onClick={addFunds}>Add funds</button>
                    <button className='hf-btn' onClick={() => setShowAddFunds(false)}>Cancel</button>
                  </div>
                </div>}
            </div>:  <div className="holding">
            <h3>Reports</h3>
            <div>
              <table className="table calc-table">
                <thead>
                  <tr className='table-row'>
                    <th className="table-head head">Company</th>
                    <th className="table-head head">Investment</th>
                    <th className="table-head head">Percentage</th>

                  </tr>
                </thead>

                <tbody>
                  { reports.map(ele => {
                    const {symbol,investmentAmt,percentGain} = ele;
                    return (
                      <tr key={ele._id} className='table-row '>
                    <th className="table-head head holds-row">{symbol}</th>
                    <th className="table-head head holds-row">{investmentAmt}</th>
                    <th className="table-head head holds-row">{percentGain}</th>
                    </tr>
                    )
                  })
                  }
                </tbody>

              </table>

            </div>

          </div>}

        </div>}
        {/* <div className="fund_bottom">
            btn
        </div> */}
      </div>
      <button className='close-holding-btn' onClick={() => setHoldingFundsOpen(false)}>Close</button>
   
       {/* popup for add percent to buy &&  autosell  */}
       {/* popup start */}
     { showAddSellPercent && <div className='buy-popup'>
          <div className="buy-container">
            <div className='buy-content'>
              <div className="buy-header" style={{backgroundColor:'#ed3535'}}>
                <h3>Sell</h3>
                <span className="material-icons" style={{ cursor: 'pointer' }} onClick={closesellPerPopup}>close</span>
              </div>
              <div className="buy-body sell-body">
                {/* <input type="number" value={investmentAmt} placeholder='Investment Amount' required onChange={(e) => setInvestmentAmt(e.target.value)}/> */}
                {/* <label for="cars">Choose a car:</label> */}
                <span className='symbol-h2'>{symbol}</span>

                <select name="number" placeholder='No of Days' value={day}  onChange={(e) => setDay(e.target.value)}>
        <option defaultValue="" >Trigger Percentage</option>
          <option value="0">0%</option>
          <option value="1">1%</option>
          <option value="2">2%</option>
          <option value="3">3%</option>
          <option value="4">4%</option>
          <option value="5">5%</option>
          <option value="6">6%</option>
          <option value="7">7%</option>
          <option value="8">8%</option>
          <option value="9">9%</option>
          <option value="10">10%</option>
          <option value="11">11%</option>
          <option value="12">12%</option>
          <option value="13">13%</option>
          <option value="14">14%</option>
          <option value="15">15%</option>
          <option value="16">16%</option>
          <option value="17">17%</option>
          <option value="18">18%</option>
          <option value="19">19%</option>
          <option value="20">20%</option>
        </select>
              </div>
              <div className="buy-footer">
                <button className={ day ? 'buy-btn back-blue' :'buy-btn'} disabled = {day?false:true} onClick={addAutoSellPercent}>Sell</button>
                <button className='buy-cancel-btn' onClick={closesellPerPopup}>Cancel</button>
              </div>
            </div>
          </div>

        </div>}
  {/* popup ends */}

     {/* confirm cancel buying popup start */}
     {showBuyCancelPopup && <div className='confirm-popup'>
      <div className="confirm-container">
       <div className="confirm-pop-body">
            Are you sure you want to cancel the order?
       </div>
       <div className="confirm-pop-footer">
        <button style={{backgroundColor:'#61aae3'}} className='pop-btn' onClick={ cancelBuyingOrder}>Yes</button>
        <button className='pop-btn' black onClick={closeCancelBuyingOrderModel}>Cancel</button>
       </div>

       
      </div>


      </div>
}
   {/* confirm cancel buying popup ends */}


     {/* confirm cancel selling popup */}
     {showSellCancelPopup && <div className='confirm-popup'>
      <div className="confirm-container">
       <div className="confirm-pop-body">
            Are you sure you want to cancel the order?
       </div>
       <div className="confirm-pop-footer">
        <button style={{backgroundColor:'#61aae3'}} className='pop-btn' onClick={ cancelSellingOrder}>Yes</button>
        <button className='pop-btn' black onClick={closeCancelSellingOrderModel}>Cancel</button>
       </div>

       
      </div>


      </div>
}

   {/* confrim popup ends */}



    </div>
  )
}

export default Holdings