import axios from "axios";
const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});


export const ScheduleByShare = (obj) => async(dispatch) => {
    try {
        const {data }= await axiosInstance.post('/handlebuy',obj,{
            headers:{
                "Content-Type":"application/json",
                'x-access-token': localStorage.getItem('token')
            }
        })
      
        return data;
        
    } catch (error) {
        return error.response.data.message;
    }

}

export const getHoldings = () => async (dispatch) => {
   try {
    dispatch({
        type:'getHoldingsRequet'
    })

    const {data} = await axiosInstance.get('/getholdings',{
        headers:{
            'x-access-token':localStorage.getItem('token')
        }
    })

   

    dispatch({
        type:'getHoldingsSuccess',
        payload:data.data,
    })
   } catch (error) {
      dispatch({
        type:'getHoldingsFailure',
        payload:error.response.data.message,

      })
   }
}

export const Addfunds = (obj) => async(dispatch) => {
    try {
        const {data} = await axiosInstance.post('/addfunds',obj,{
            headers:{
                "Content-Type":"application/json",
                'x-access-token': localStorage.getItem('token')
            }
        })
        return data;
        
    } catch (error) {
        
    }
}

export const ScheduleBuyShare = (obj) => async(dispatch) => {
    try {
        const {data} = await axiosInstance.post('/autosell',obj,{
            headers:{
                "Content-Type":"application/json",
                'x-access-token': localStorage.getItem('token')
            }
        })
        return data;
        
    } catch (error) {
        
    }
}

export const getMatrixData = async(symbol,value) => {
    try {
        const url = symbol || value ? `/data_matrix?symbol=${symbol}&value=${value}` :`/data_matrix`;
        const {data} = await axiosInstance.get(url,{
            headers:{
                'x-access-token':localStorage.getItem('token')
            }
        });
        return data;
    } catch (error) {
        return error;
    }
}

export const getMatrixDataByDateRange = async(dateObj) => {
    try {
        // const {fromDate,toDate,symbol} = dateObj;
        const {data} = await axiosInstance.post(`/data_matrix_by_date_range`,dateObj,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error;
    }

}

export const getMatrixAnalysisDataByDateRange = async(dateObj) => {
    try {
        // const {fromDate,toDate,symbol} = dateObj;
        const {data} = await axiosInstance.post(`/data_matrix_by_date_range/all_analysis`,dateObj,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error;
    }

}
export const getPchnageDataByDateRange = async(dateObj) => {
    try {
        // const {fromDate,toDate,symbol} = dateObj;
        const {data} = await axiosInstance.post(`/per_change`,dateObj,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error;
    }

}

export const sendBuySellNotification = async(dateObj) => {
    try {
        // const {fromDate,toDate,symbol} = dateObj;
        const {data} = await axiosInstance.post(`/customer/notification`,dateObj,{
            headers:{
                'x-access-token':localStorage.getItem('token'),
                "Content-Type":"application/json",
            }
        })
        return data;
    } catch (error) {
        return error.response.data;
    }
}

