import React, { useEffect, useState } from 'react';
import './BuySellNotificationModal.css';

const BuySellNotificationModal = ({ show, handleClose, handleSubmit }) => {
  const [note, setNote] = useState('');
  const [option, setOption] = useState('');

  useEffect(() => {
    resetVariables();
 },[show]);

    const resetVariables = () => {
      setNote("");
      setOption("")
    }


  if (!show) {
    return null;
  }

  return (
    <div className="port-popup-overlay">
      <div className="port-popup">
        <h2>Send Notification</h2>
        <div className="port-form-group">
          <label htmlFor="port-dropdown">Choose an option:</label>
          <select
            id="port-dropdown"
            value={option}
            onChange={(e) => setOption(e.target.value)}
          >
            <option value="">Select Call</option>
            <option value="Buy">Buy</option>
            <option value="Sell">Sell</option>
          </select>
        </div>
        <div className="port-form-group">
          <label htmlFor="port-note">Note:</label>
          <textarea
            id="port-note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></textarea>
        </div>
        <div className="port-buttons">
          <button onClick={handleClose}>Close</button>
          <button onClick={() => handleSubmit(note, option)}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default BuySellNotificationModal;
