
import React, { useState } from 'react'
import './ManualDataUpload.css'
import Papa from "papaparse";
import { UploadaMaualData } from '../../Actions/allCategory';
import { useAlert } from 'react-alert'


const ManualDataUpload = ({setManualUploadOpen}) => {
   const [file,setFile] = useState(null);
   const [userHolding,setUserHolding] = useState([]);
   const [showAverageTable,setShowAverageTable] = useState(false);
   const alert = useAlert();
    const handleFileChange = (e) => {
       setFile(e.target.files[0]);
    }

    const handleManualDataSybmit = async() => {
        if(userHolding.length === 0){
            alert.error("Please select the file with proper data")
            return;
        }
        const res = await UploadaMaualData(userHolding);
        if(res.success){
            alert.success(res.message);
            setFile("");
            setUserHolding([]);
            setShowAverageTable(false)
        }else{
            alert.error(res.message)
        }
    }

    const handleParse = () => {
        if(!file){
            alert.error("Please select the file first")
            return;
        }
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                let parsedData = results.data;
                parsedData = parsedData.map(ele => {
                   return {...ele,price:Number(ele.price.replace(/,/g, '')),pChange:Number(ele.pChange),volume:Number(ele.volume.replace(/,/g, '')),percent:Number(ele.percent),vwap:Number(ele.vwap.replace(/,/g, '')),deliveryQuantity:Number(ele.deliveryQuantity.replace(/,/g, ''))}
                })


                
          setUserHolding(parsedData);
          setShowAverageTable(true)
          },
          });
    }

  return (
    <div className="suggest-average-popup">
    <span className="material-icons close-analysis" onClick={() => setManualUploadOpen(false)}>close</span>

          <div className='suggest-file-input'>
          <label htmlFor="csvInput" style={{ display: "block" }}>
            Enter CSV File
        
        <input
            className="file-input"
            onChange={handleFileChange}
            id="csvInput"
            accept=".csv"
            name="file"
            type="File"
            multiple
        />
        </label>
          </div>
        <div className="buttons">	
         <div>
         <button  onClick={handleParse} className= 'upload-botton' style={{marginBottom:'5px'}}>Upload</button>
        </div>
        </div>
 
      { showAverageTable && <table className="table calc-table" style={{overflow:'auto'}}>
      <thead>
      <tr className='table-row'>
          <th className="table-head head">symbol</th>
          <th className="table-head head">price</th>
          <th className="table-head head">pChange</th>
          <th className="table-head head">volume</th>
          <th className="table-head head">percent</th>
          <th className="table-head head">vwap</th>
          <th className="table-head head">deliveryQuantity</th>
      </tr>
      </thead>
      <tbody>
        {
          userHolding.map((ele,i) => {
            // let oldAverageCost = ele['Avg. cost'];
            // let newAverageCost = ele['averagePrice'];
            // let oldAveragePer = ele['Net chg.'];
            // let newAveragePer = ele['percentDown'];
            return(
              <tr className ='table-row' key={i}>
           <td className="table-data t-data">{ele.symbol}</td>
           <td className="table-data t-data">{ele.price}</td>
           <td className="table-data t-data">{ele.pChange}</td>
           <td className="table-data t-data">{ele.volume}</td>
           <td className="table-data t-data">{ele.percent}</td>
           <td className="table-data t-data">{ele.vwap}</td>
           <td className="table-data t-data">{ele.deliveryQuantity}</td>
      </tr>
            )
          })
        }
      </tbody>
  </table>}
      <button className='manual-upload-submit' onClick={handleManualDataSybmit}>Submit</button>
        </div>
  )      
}

export default ManualDataUpload