export const MergePerObject = (dailyPercentGain,dailyPercentLoss) => {
    const data = [];
    const keyArr = Object.keys(dailyPercentGain)
    keyArr.forEach(ele => {
      const randomNum = Math.floor((Math.random() * 1000000) + 1);
        let d = {
            day:ele,
            percentGainCount:dailyPercentGain[ele].count,
            percentLossCount:dailyPercentLoss[ele].count,
            dates:[...dailyPercentGain[ele].dates,...dailyPercentLoss[ele].dates],
            id:randomNum
        }
        data.push(d)
        }
    )
    return data;
    }

  export const ObjectToKeyValueArr = (Obj) => {
    const data = [];
    const keyArr = Object.keys(Obj)
    keyArr.forEach(ele => {
      const randomNum = Math.floor((Math.random() * 1000000) + 1);
        let d = {
            key:ele,
            value:Obj[ele],
            id:randomNum
        }
        data.push(d)
        }
    )
    return data;
    }  

    export const getWeekNumber = (date) => {
        // sample date format -> 2019-01-31
        if(Number(date.split("-")[2]) <= 7 ){
            return 1;
        }
        else if(Number(date.split("-")[2]) <= 14 && Number(date.split("-")[2]) > 7){
          return 2;
        }
        else if(Number(date.split("-")[2]) <= 21 && Number(date.split("-")[2]) > 14){
           return 3;
        }
        else if(Number(date.split("-")[2]) <= 31 && Number(date.split("-")[2]) > 21){
          return 4;
        }
        return -1;
    }

    export const getMostRepeatingArrayEle = (arr) => {
      let obj = {};
      let max = 0;
      let element;
      arr.forEach(ele => {
        if(obj[ele]){
            obj[ele]+=1;
        }else{
            obj[ele] = 1;
        }
      })

      for (let key in obj){
        if(max<obj[key]){
          max = obj[key];
          element = key;
        }
      }
      return element;
    }


    export const handleTrendFilter = (data,positive,daily) => {
      if(positive){
        //  if positive then match the positive pattern
        // A,H,F,E,G
        const pattern = ["A","H","F","E","G"];
        const pmatch = pattern.map(ele => {
            if(data.find(item => item.key === ele)){
                return true;
            }else{
                return false;
            }
        })
        const asd = pmatch.filter(el => el === true );
        if(daily){
          return asd.length >= 2 ? true : false;
        }
        return asd.length >= 3 ? true : false;

      }else{
        //match negative pattern 
        // A,B,C,D
        const pattern = ["A","B","C","D"];
        const pmatch = pattern.map(ele => {
            if(data.find(item => item.key === ele)){
                return true;
            }else{
                return false;
            }
        })
        const asd = pmatch.filter(el => el === true );
        if(daily){
          return asd.length >= 2 ? true : false;
        }
        return asd.length >= 3 ? true : false;
      }
    }