import React from 'react'
import { memo } from "react";
import "./AllPages.css"


const NiftyTotalMarket = () => {
  return (
    <div className="main-body">
   
    <div>Nifty Total Market</div>
    </div>
  )
}

export default memo(NiftyTotalMarket)