import React, { useState } from 'react'
import './SuggestAverage.css'
import Papa from "papaparse";


const SuggestAverage = ({setShowSuggestAverage}) => {
   const [file,setFile] = useState(null);
   const [funds,setFunds] = useState("");
   const [userHolding,setUserHolding] = useState([]);
   const [showAverageTable,setShowAverageTable] = useState(false);

    const handleFileChange = (e) => {
       setFile(e.target.files[0]);
    }

    const handleParse = () => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                let parsedData = results.data;
                
                const updatedData = [];
                parsedData.forEach(ele => {
                    const stockAvgCost = Number(ele['Avg. cost']);
                    const StockQuantity = Number(ele['Qty.']);
                    const StockCurrentPrice = Number(ele['LTP'])
                    const totalInvestment = (stockAvgCost*StockQuantity)+Number(funds);
                    const totalStock = StockQuantity + (funds/StockCurrentPrice);
                  
                                        
                    const averagePrice = totalInvestment/totalStock;
                    const onePercent = averagePrice/100;
                    const amount  = averagePrice - StockCurrentPrice;
                    const percentDown = amount/onePercent
                    let data = {...ele,percentDown:-Number(percentDown.toFixed(2)),averagePrice:Number(averagePrice.toFixed(2))}
                    updatedData.push(data);
          })
          updatedData.sort((a, b) => b.percentDown - a.percentDown);
          setUserHolding(updatedData);
          setShowAverageTable(true)
          },
          });
    }

  return (
    <div className="suggest-average-popup">
    <span className="material-icons close-analysis" onClick={() => setShowSuggestAverage(false)}>close</span>

          <div className='suggest-file-input'>
          <label htmlFor="csvInput" style={{ display: "block" }}>
            Enter CSV File
        
        <input
            className="file-input"
            onChange={handleFileChange}
            id="csvInput"
            accept=".csv"
            name="file"
            type="File"
            multiple
        />
        </label>
         <input type="number" 
         className='average-add-funds'
         placeholder="Add Funds"
         value={funds}
         onChange={(e) => setFunds(e.target.value)}
         />
          </div>
        <div className="buttons">	
         <div>
         <button  onClick={handleParse} className= 'upload-botton' style={{marginBottom:'5px'}}>Upload</button>
        </div>
        </div>
 
      { showAverageTable && <table className="table calc-table" style={{overflow:'auto'}}>
      <thead>
      <tr className='table-row'>
          <th className="table-head head">Company</th>
          <th className="table-head head"> old Average Price</th>
          <th className="table-head head"> new Average Price</th>
          <th className="table-head head"> old Average percentage</th>
          <th className="table-head head"> new Average percentage</th>
      </tr>
      </thead>
      <tbody>
        {
          userHolding.map((ele,i) => {
            let oldAverageCost = ele['Avg. cost'];
            let newAverageCost = ele['averagePrice'];
            let oldAveragePer = ele['Net chg.'];
            let newAveragePer = ele['percentDown'];
            return(
              <tr className ='table-row' key={i}>
           <td className="table-data t-data">{ele['Instrument']}</td>
           <td className="table-data t-data">{oldAverageCost}</td>
           <td className="table-data t-data">{newAverageCost}</td>
           <td className="table-data t-data">{oldAveragePer}</td>
           <td className="table-data t-data">{newAveragePer}</td>
      </tr>
            )
          })
        }
      </tbody>
  </table>}

        </div>
  )      
}

export default SuggestAverage