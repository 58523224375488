import React, { useEffect, useState } from 'react'
import './DataMatrix.css'
import { getMatrixData, getMatrixDataByDateRange } from '../../Actions/BuySell'
import DatePicker from "react-datepicker";
import { format } from 'timeago.js';
import DataMatrixResultCompareModel from '../DataMatrixResultCompareModel/DataMatrixResultCompareModel';
import Select from 'react-select';
import { addPattern } from '../../Actions/Matrix';
import { useAlert } from 'react-alert';

const patterns = {
  "A":"true_false_false_true",
  "B":"true_false_false_false",
  "C":"true_false_true_false",
  "D":"true_false_true_true",
  "E":"false_false_true_true",
  "F":"false_false_true_false",
  "G":"false_false_false_false",
  "H":"false_false_false_true",
}

const DataMatrix = ({setDataMatrixOpen}) => {
    const [selectedSymbol,setSelectedSymbol] = useState("");
    const [symbolList,setSymbolList] = useState([]);
    const [dailyData,setDailyData] = useState([]);
    const [showCompanyColumn,setShowCompanyColumn] = useState(false);
    const [fromDate,setFromDate] = useState(new Date());
    const [toDate,setToDate] = useState(new Date());
    const [showDateRangeData,setShowDateRangeData] = useState(false);
    const [dateRangeData,setDateRangeData] = useState({});
    const [loading,setLoading] = useState(false);
    const [showSelectedSymbol,setShowSelectedSymbol] = useState(false);
    const [selectedSymbolData,setSelectedSymbolData] = useState([]);
    const [showResultButtonData,setshowResultButtonData] = useState(false);
    const [showCompareResult,setshowCompareResult] = useState(false);
    const [compareResultData,setCompareResultData] = useState([])
    const [resultButttonData,setResultButttonData] = useState([]);
    const [trend,setTrend] = useState('positive');
    // const [loading,setLoading] = use
    const alert = useAlert();
 
  useEffect(() => {
    const getData = async () => {
        const res = await getMatrixData();
        if(res.status){
            setSymbolList(res.symbol);
            setDailyData(res.data);
        }
    }
    getData();
  }, [])

  const handleSelectSymbol = async(data) => {
    const val = data.value
    setSelectedSymbol(data);
    setShowDateRangeData(false);
    if(val === 'all_avg'){
      const res = await getMatrixData("","all_avg");
      if(res.status){
          setSymbolList(res.symbol);
          setDailyData(res.data);
          setShowCompanyColumn(res.allAverageValue)
          setResultButttonData([]);
      }
    }else{
      const res = await getMatrixData(val,"");
      if(res.status){
          setSymbolList(res.symbol);
          setDailyData(res.data);
          setShowCompanyColumn(res.allAverageValue)
          setResultButttonData([]);
      }
    }
    
  }

  const getDataByMatrixRange = async() => {
    // if(!fromDate || !toDate || fromDate.toDateString().slice(0,10) === toDate.toDateString().slice(0,10)){
    if(!fromDate || !toDate){
      window.alert("Please select the valid date range");
      return;
    }
    if(!selectedSymbol?.value){
      window.alert("Please select the symbol");
      return;
    }
    setShowDateRangeData(true)
    setDateRangeData({});
    setLoading(true)
    const date = {
      fromDate:fromDate.toISOString(),
      toDate:toDate.toISOString(),
      symbol:selectedSymbol?.value
    }
    const res = await getMatrixDataByDateRange(date);
    setLoading(false)
    if(res.success){
     setDateRangeData(res.data);
     setResultButttonData(res.resultData);
    }
  }

  const getDataTillClickRow = (data,index)=> {
    setShowSelectedSymbol(true)
    let percentsum = 0;
    let volumesum = 0;
    let averagepricesum = 0;
    let deliveryquantitysum = 0;
    let isHighPercent;
    let isHighVolume;
    let isHighaveragePrice;
    let isHighDeliveryQuantity; 
    // get the data till the cicked row index
    const datas = data.slice(0,index+1);
    for (let j = 0; j < datas.length; j++){
        percentsum = percentsum + datas[j].percent;
        volumesum = volumesum + datas[j].volume;
        averagepricesum = averagepricesum + datas[j].vwap;
        deliveryquantitysum = deliveryquantitysum + datas[j].deliveryQuantity; 
    }

    percentsum = percentsum/datas.length;
    volumesum = volumesum/datas.length;
    averagepricesum = averagepricesum/datas.length;
    deliveryquantitysum = deliveryquantitysum/datas.length;
    
    isHighPercent = datas[datas.length-1].percent < percentsum ? true : false;
    isHighVolume = datas[datas.length-1].volume < volumesum ? true : false;
    isHighaveragePrice = datas[datas.length-1].vwap < averagepricesum ? true : false;
    isHighDeliveryQuantity = datas[datas.length-1].deliveryQuantity < deliveryquantitysum ? true : false;

   datas.push({percent:percentsum,volume:volumesum,vwap:averagepricesum,deliveryQuantity:deliveryquantitysum,isaverage:true,isHighPercent,isHighVolume,isHighaveragePrice,isHighDeliveryQuantity})
   setSelectedSymbolData(datas)
  }

 const removeNullData = (data,israngeSelected) => {
  if(israngeSelected){
    const keys = Object.keys(data);
    const newData = {};
    keys.forEach(ele => {
     const d = data[ele].filter(ele => ele.vwap && ele.volume && ele.percent && ele.deliveryQuantity)
     newData[ele] = d;
    })
    return newData
  }else{
    if(!data || data.length === 0) return [];
   return data.filter(ele => ele.vwap && ele.volume && ele.percent && ele.deliveryQuantity);
  }
 }


 const handleShowComputeResult = () => {
  if(!fromDate || !toDate || fromDate.toDateString().slice(0,10) === toDate.toDateString().slice(0,10)){
    window.alert("Please select the valid date range");
    return;
  }
  setshowCompareResult(true)
 }

 const handlePatternSave = async(symbol,data) => {
  const date1 = new Date(fromDate);
  const date2 = new Date(toDate);
  let Difference_In_Time = date2.getTime() - date1.getTime();
  let Difference_In_Days =Math.round(Difference_In_Time / (1000 * 3600 * 24));
  const pattern_type = Difference_In_Days === 0 ? 'daily' : Difference_In_Days <= 6 ? 'weekly' : "monthly"
let pattern = '';
data.forEach(ele => {
  pattern = pattern + ele.key;
})

 const apiData = {
     pattern,
     symbol,
     patternData:data,
     type:pattern_type,
     trend,
 }

 
  if(Difference_In_Days < 0 || data.length === 0 || !pattern){
      alert.error("Please enter the pattern")
      return;
  }
 
  const res = await addPattern(apiData);
  if(res.success){
      alert.success(res.message);
  }else{
      alert.error(res.message)
  }
}

  return (
    <>
    {/* compare multiple result modal */}
    {showCompareResult && <DataMatrixResultCompareModel
      symbolList={symbolList.map(ele => {return { value:ele, label:ele }})}
      setshowCompareResult ={setshowCompareResult}
      toDate={toDate.toISOString()}
      fromDate={fromDate.toISOString()}
      isDateEqual ={fromDate.toDateString().slice(0,10) === toDate.toDateString().slice(0,10)?true:false}
     />
     }



     {/* the model to show the results for the specific selected records  */}
      {/* popup start */}
      {(showSelectedSymbol || showResultButtonData) && <div className='select-symbol-data'>
      <span className="material-icons close-analysis" onClick={() => {setShowSelectedSymbol(false);setshowResultButtonData(false)}}>close</span>
           <h3 className='c-symbol'>{selectedSymbol?.value}</h3>
           {/* conditionally render the result data and table data till the row selected */}
            {!showResultButtonData ? 
            <table className="data-matrix-table">
                <thead>
                  <tr className='table-row'>
                   {showCompanyColumn && <th className="table-head head">Company</th>}
                    <th className="table-head head">vwap</th>
                    <th className="table-head head">volume</th>
                    <th className="table-head head">deliveryQuantity</th>
                    <th className="table-head head">percent</th>

                  </tr>
                </thead>

                <tbody>
                  { selectedSymbolData.map(ele => {
                    const {vwap,volume,deliveryQuantity,percent,isaverage,isHighPercent,isHighVolume,isHighaveragePrice,isHighDeliveryQuantity,symbol,date} = ele;
                    return (
                      <tr key={ele._id} className='table-row'>


{showCompanyColumn && <th className='table-head head holds-row'>{symbol}</th>}

                    
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{vwap.toFixed(2)}{isaverage &&<span class={isHighaveragePrice ?"material-icons avggreen":"material-icons avgred"}>
{isHighaveragePrice ?"arrow_upward": isHighaveragePrice === false ? "arrow_downward":""}
</span>}</th>
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{volume.toFixed(2)}{isaverage &&<span class={isHighVolume ?"material-icons avggreen":"material-icons avgred"}>
{isHighVolume ?"arrow_upward": isHighVolume === false ? "arrow_downward":""}
</span>}</th>
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{deliveryQuantity.toFixed(2)} {isaverage &&<span class={isHighDeliveryQuantity ?"material-icons avggreen":"material-icons avgred"}>
{isHighDeliveryQuantity ?"arrow_upward": isHighDeliveryQuantity === false ? "arrow_downward":""}
</span>}</th>
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{percent.toFixed(2)}  {isaverage &&<span class={isHighPercent ?"material-icons avggreen":"material-icons avgred"}>
{isHighPercent ?"arrow_upward": isHighPercent === false ? "arrow_downward":""}
</span>}
</th>
                   
                    </tr>
                     )
                  })
                  } 
                </tbody>

              </table>
            :
            <>
            <table className="data-matrix-table">
                <thead>
                  <tr className='table-row'>
                    <th className="table-head head">Pattern</th>
                    <th className="table-head head">Count</th>
                  </tr>
                </thead>

                <tbody>
                  { resultButttonData.map((ele,i) => {
                    const {key,count} = ele;
                    const pattern = patterns[key] ? patterns[key]: key; 
                    return (
                      <tr key={`resultbutton${i}`} className='table-row'>
                      <th className='table-head head holds-row'>
                        <div className='table-data-result'>
                        {
                         pattern.split("_").map(ele => {
                          if(ele){
                            return (
                              <span class={ele === "true" ? "material-icons avggreen":"material-icons avgred"} style={{fontSize:"32px"}}>
                              {ele === "true" ?"arrow_upward":"arrow_downward"}
                              </span>
                            )  
                          }
                         })
                      }
                      {key}
                        </div>
                      </th>
                      <th className='table-head head holds-row'>{count}</th>
                    </tr>
                     )
                  })
                  } 
                </tbody>

              </table>
              <div>
               <div className='trend-cont'>
                <input type="radio" name='trend' checked={trend === "positive" ? true : false}  onChange={() => setTrend('positive')}/> <label>Positive</label>
                <input style={{marginLeft:"30px"}} type="radio" name='trend'  checked={trend === "negative" ? true : false} onChange={() => setTrend('negative')}/> <label>Negative</label>
                <input style={{marginLeft:"30px"}} type="radio" name='trend'  checked={trend === "pre" ? true : false} onChange={() => setTrend('pre')}/> <label>Pre</label>
               </div>
              <button onClick={() => handlePatternSave(selectedSymbol?.value,resultButttonData)} className='add-type-btn' style={{marginTop:"10px"}}>Save Pattern</button>
              </div>
              </>  
            }
       
        </div>}

      {/* popup end */}


    <div className='data-matrix-popup'>
      
    <span className="material-icons close-analysis" onClick={() => setDataMatrixOpen(false)}>close</span>
    <div className="matrix-container">
     
      <div className="matrix-header">
      <h3>DataMatrix</h3>
      {/* <select name="number" placeholder='Select Symbol' className='symbol-select' value={selectedSymbol}  onChange={handleSelectSymbol}>
        <option value=""  >Select Symbol</option>
        <option value="all_avg"  >All high Low</option>
        {symbolList.length > 0 &&  symbolList.map(ele => {
            return(
          <option key={ele} value={ele}>{ele}</option>
            )
        }) }
         
        </select> */}
         <Select
                    value={selectedSymbol}
                    onChange={handleSelectSymbol}
                    options={[{value:"all_avg",label:"All high Low"},...symbolList.map(ele => {return { value:ele, label:ele }})]}
                    // isMulti
                    className='result-symbol-select'
                />
        <div className="date-range-container">
          <div className='date-picker-div'>
          <DatePicker
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        dateFormat='dd/MM/yyyy'
                        wrapperClassName="datamatrix-calender" 
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        // includeDates={Active}
                        // minDate={startDate}
                        maxDate={new Date()}
                        closeOnScroll={true}
                        shouldCloseOnSelect={true}
                        />
                        <span className='dash-separator'>—</span>
          <DatePicker
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                        dateFormat='dd/MM/yyyy'
                        wrapperClassName="datamatrix-calender" 
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        // includeDates={Active}
                        minDate={fromDate}
                        maxDate={new Date()}
                        closeOnScroll={true}
                        shouldCloseOnSelect={true}
                        />
                        <span className="material-icons arrow-getdata" onClick={getDataByMatrixRange}>arrow_right_alt</span>
          </div>
          
        </div>
        <div className="resultbtn" onClick={() => setshowResultButtonData(true)}>results</div>
        <div className="resultbtn" onClick={handleShowComputeResult}>Com_Res</div>

      </div>
      <div className="matrix-body">
       
      <div>
      <h3 className='c-symbol'>{selectedSymbol?.value}</h3>
      {/* if date range data is not there then show sigle table  */}
      {!showDateRangeData ? <table className="data-matrix-table">
                <thead>
                  <tr className='table-row'>
                   {showCompanyColumn && <th className="table-head head">Company</th>}
                    <th className="table-head head">vwap</th>
                    <th className="table-head head">volume</th>
                    <th className="table-head head">deliveryQuantity</th>
                    <th className="table-head head">percent</th>

                  </tr>
                </thead>

                <tbody>
                  { dailyData.map(ele => {
                    const {vwap,volume,deliveryQuantity,percent,isaverage,isHighPercent,isHighVolume,isHighaveragePrice,isHighDeliveryQuantity,symbol,date} = ele;
                    return (
                      <tr key={ele._id} className='table-row'>


{showCompanyColumn && <th className='table-head head holds-row'>{symbol}</th>}

                    
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{vwap.toFixed(2)}{isaverage &&<span class={isHighaveragePrice ?"material-icons avggreen":"material-icons avgred"}>
{isHighaveragePrice ?"arrow_upward": isHighaveragePrice === false ? "arrow_downward":""}
</span>}</th>
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{volume.toFixed(2)}{isaverage &&<span class={isHighVolume ?"material-icons avggreen":"material-icons avgred"}>
{isHighVolume ?"arrow_upward": isHighVolume === false ? "arrow_downward":""}
</span>}</th>
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{deliveryQuantity.toFixed(2)} {isaverage &&<span class={isHighDeliveryQuantity ?"material-icons avggreen":"material-icons avgred"}>
{isHighDeliveryQuantity ?"arrow_upward": isHighDeliveryQuantity === false ? "arrow_downward":""}
</span>}</th>
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{percent.toFixed(2)}  {isaverage &&<span class={isHighPercent ?"material-icons avggreen":"material-icons avgred"}>
{isHighPercent ?"arrow_upward": isHighPercent === false ? "arrow_downward":""}
</span>} </th>
<th className={isaverage ?"table-head head holds-row avggreen time-small":"table-head head holds-row time-small"}>{format(date)}</th>
                    </tr>
                     )
                  })
                  } 
                </tbody>

              </table>
              // show the date range table data
              :              
              Object.keys(dateRangeData)?.length > 0 ? 
              <>
             {Object.keys(dateRangeData).map(element => {
              const data = dateRangeData[element];
               return (
                  <>
                  <h4 style={{margin:"6px 7px"}}>{element}</h4>
                  <table className="data-matrix-table">
                <thead>
                  <tr className='table-row'>
                   {showCompanyColumn && <th className="table-head head">Company</th>}
                    <th className="table-head head">vwap</th>
                    <th className="table-head head">volume</th>
                    <th className="table-head head">deliveryQuantity</th>
                    <th className="table-head head">percent</th>

                  </tr>
                </thead>

                <tbody>
                { data.map((ele,i) => {
                    const {vwap,volume,deliveryQuantity,percent,isaverage,isHighPercent,isHighVolume,isHighaveragePrice,isHighDeliveryQuantity,symbol,date} = ele;
                    return (
                      <tr key={ele._id} className='table-row'onClick={() => getDataTillClickRow(data,i)}>


{showCompanyColumn && <th className='table-head head holds-row'>{symbol}</th>}

                    
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{vwap?.toFixed(2)}{isaverage &&<span class={isHighaveragePrice ?"material-icons avggreen":"material-icons avgred"}>
{isHighaveragePrice ?"arrow_upward": isHighaveragePrice === false ? "arrow_downward":""}
</span>}</th>
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{volume?.toFixed(2)}{isaverage &&<span class={isHighVolume ?"material-icons avggreen":"material-icons avgred"}>
{isHighVolume ?"arrow_upward": isHighVolume === false ? "arrow_downward":""}
</span>}</th>
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{deliveryQuantity?.toFixed(2)} {isaverage &&<span class={isHighDeliveryQuantity ?"material-icons avggreen":"material-icons avgred"}>
{isHighDeliveryQuantity ?"arrow_upward": isHighDeliveryQuantity === false ? "arrow_downward":""}
</span>}</th>
                    <th className={isaverage ?"table-head head holds-row avggreen":"table-head head holds-row"}>{percent?.toFixed(2)}  {isaverage &&<span class={isHighPercent ?"material-icons avggreen":"material-icons avgred"}>
{isHighPercent ?"arrow_upward": isHighPercent === false ? "arrow_downward":""}
</span>} </th>
<th className={isaverage ?"table-head head holds-row avggreen time-small":"table-head head holds-row time-small"}>{format(date)}</th>
                    </tr>
                     )
                  })
                  }

                </tbody>

</table>
                  </>
               )
             })}
              </>
              
               :loading ? "Loading ..." :"No data found"}
      </div>

           </div>
     </div>
        </div>
        </>
  )
}

export default DataMatrix