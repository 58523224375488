import React, { useEffect, useState } from 'react'
import './Notification.css'
import { getMatrixData, getMatrixDataByDateRange } from '../../Actions/BuySell'
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import {format} from 'timeago.js'


const Notifications = ({setNotificationOpen}) => {
    const { notifications } = useSelector(state => state.user);
    const [tab,setTab] = useState("regular")

  return (
    <>
  
  <div className='notification-popup'>
      
      <span className="material-icons close-analysis" onClick={() => setNotificationOpen(false)}>close</span>
      <div className="matrix-container">
       
        <div className="notification-header">
        <h3>Notifications</h3>
        <div>
          <span className={tab === "regular"?'active-notification-tab':""}onClick={()=>setTab('regular')}>Regular</span>
          <span className={tab === "pattern"?'active-notification-tab':""} onClick={() => setTab("pattern")}>Pattern</span>
          </div>
        </div>
        <div className="notification-body">
          {tab === 'regular' ? <div className='notification-container'>
           {notifications.filter(ele=> ele.pattern === false).length > 0 ? notifications.filter(ele=> ele.pattern === false).map((ele,i)=> {
          //   if(ele.pattern){
          //     return;
          //   }
          return (
            <>
            <div className='notification-ele'>
            {/* <div className={ele.pattern ?'notification-green':""}>{ele.pattern ?`${ele.companyName} (${ele.count}D)`:ele.companyName}</div>
            <div className={ele.pattern ?'notification-green':""}>{format(ele?.createdAt)}</div> */}
            
            <div className={ele.pattern === true ? "notification-green" : ""} >{ele.pattern ?`${ele.companyName} (${ele.count}D)`:ele.companyName}</div>
            <div >{format(ele?.createdAt)}</div>
            
            </div>
            </>
          )
         }):                   // for pattern notification
         "No Notications"}
           </div> :
            <div className='notification-container'>
            {notifications.length > 0 ? notifications.map((ele,i)=> {
            if(!ele.pattern){
              return;
            }
           return (
             <>
             <div className='notification-ele'>
             {/* <div className={ele.pattern ?'notification-green':""}>{ele.pattern ?`${ele.companyName} (${ele.count}D)`:ele.companyName}</div>
             <div className={ele.pattern ?'notification-green':""}>{format(ele?.createdAt)}</div> */}
             
             {/* <div className={ele.pattern === true ? "notification-green" : ""} >{ele.pattern ?`${ele.companyName} (${ele.count}D)`:ele.companyName}</div> */}
             <div>{`${ele.companyName} (${ele.patternType})`}</div>
             <div >{format(ele?.createdAt)}</div>
             
             </div>
             </>
           )
          }):"No Notications"}
            </div>}

        </div>
        </div>
        </div>
        </>
  )
}

export default Notifications