import React, { useEffect, useState } from 'react'
import './AIMatrix.css'
import { useAlert } from 'react-alert';
import { getAIMatrixData } from '../../Actions/Matrix';
import DatePicker from "react-datepicker";


const AIMatrix = ({setAIMatrixOpen}) => {
    const alert = useAlert();
    const [search,setSearch] = useState("");
    const [trend,setTrend] = useState('positive');
    const [duration,setDuration] = useState('daily');
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [message,setMessage] = useState("No Data Found");
    const [fromDate,setFromDate] = useState(new Date());
    const handleSearchSymbol = (e) => {
      setSearch(e.target.value);
    }
    const [selectedSymbol,setSelectedSymbol] = useState("");
    const [showSelectedSymbol,setShowSelectedSymbol] = useState(false);
    const [selectedSymbolData,SetSelectedSymbolData] = useState("");


    const getData = async() => {
      let data ={};
      if(search){
        if(search.length <= 1){
          alert.error("Please select the proper symbol")
          return
        }
        data = {symbol:search.toUpperCase()}
      }
       data = {...data,
        trend,
        pattern_type:duration,
        startDate:fromDate
      }
      setLoading(true);
      setData([])
      setMessage("")
     const res = await getAIMatrixData(data);
     setLoading(false);
     if(res.success){
      if(res.data.length === 0){
       setMessage("No Data Found");
      }
      setData(res.data);
     }else{
      // alert.error(res.message);
      setMessage(res.message);
     }
    }

    useEffect(() => {
     getData();
    },[duration,trend,fromDate])

    const handleSelectSymbol = (item) => {
      setSelectedSymbol(item);
      SetSelectedSymbolData(item.resultButttonData);
      setShowSelectedSymbol(true)
    }

  return (
    <>
      {showSelectedSymbol  && 
      <div className='select-symbol-data'>
      <span className="material-icons close-analysis" onClick={() => {setShowSelectedSymbol(false)}}>close</span>
           <h3 className='c-symbol'>{selectedSymbol?.singleSymbol} <span style={{color:selectedSymbol.positive ? "green" : "red",paddingLeft:"5px"}} className='aim-percent'>( {selectedSymbol?.perChange?.toFixed(2) || ""}% )</span></h3>
        
             
            <table className="data-matrix-table">
                <thead>
                  <tr className='table-row'>
                   <th className="table-head head">Pattern</th>
                    <th className="table-head head">Count</th>
                  </tr>
                </thead>

                <tbody>
                  {selectedSymbolData?.length > 0 && selectedSymbolData.map(ele => {
                    return (
                      <tr key={ele._id} className='table-row'>
   <th className='table-head head holds-row'>{ele.key}</th>
   <th className='table-head head holds-row'>{ele.count}</th>
    </tr>
                     )
                  })
                  } 
                </tbody>

              </table>          
            
       
        </div>}





    <div className='ai-matrix'>
    <span className="material-icons close-analysis" onClick={() => setAIMatrixOpen(false)}>close</span>
    <div className='aiMatrix-cont'>
      <h3 className='trends-head'>Trends</h3>
      <div className='aim-search-cont'>
        <input type="text" 
          value={search}
          onChange={handleSearchSymbol}
          placeholder='Enter the symbol'
        />
        <button onClick={() => getData()}>Search</button>
      </div>
      <div style={{display:'flex',gap:"50px",alignItems:'center'}}>
      <div className='trend-cont'>
                <input type="radio" name='trend' checked={trend === "positive" ? true : false}  onChange={() => setTrend('positive')}/> <label>Positive</label>
                <input style={{marginLeft:"30px"}} type="radio" name='trend'  checked={trend === "negative" ? true : false} onChange={() => setTrend('negative')}/> <label>Negative</label>
                <input style={{marginLeft:"30px"}} type="radio" name='trend'  checked={trend === "pre" ? true : false} onChange={() => setTrend('pre')}/> <label>Pre</label>
               </div>
      
      <div className='aim-duration'>
        <button className={duration=== "daily"?'active':""} onClick={() => setDuration('daily')}>Daily</button>
        <button className={duration=== "weekly"?'active':""} onClick={() => setDuration('weekly')}>Weekly</button>
        <button className={duration=== "monthly"?'active':""} onClick={() => setDuration('monthly')}>Monthly</button>
      </div>

      <div style={{height:"20px",width:"100px"}}>
      <DatePicker
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        dateFormat='dd/MM/yyyy'
                        wrapperClassName="datamatrix-calender" 
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        // includeDates={Active}
                        // minDate={startDate}
                        maxDate={new Date()}
                        closeOnScroll={true}
                        shouldCloseOnSelect={true}
                        />
      </div>

      </div>

      {/* <hr className='aim-hr'/> */}
      <div
      className='aim-res-cont'
      >
       {loading  ? <div>Loading....</div> : 
       data.length > 0 ?  data.map(ele => {
        return(
          <div className='aim-res'> 
          <span >{ele.pattern} </span>
          <span >{ele.count} </span>
          <span >{ele.consistentCount}</span>
          <div className='ai-slist'>
            {ele?.matchSybols?.length > 0 ? ele.matchSybols.map((item,index) => {
               return(
                <span onClick={() => handleSelectSymbol(item)}j style={{color:item.positive ? "green" : "red",padding:"2px",borderRadius:"6px",cursor:"pointer"}}>{`${item.singleSymbol}${index < ele.matchSybols.length-1 ? ",":"" }`}</span>
               )
            }) : "-"}
            
            </div>
                  </div>
        )
       })  :<div>{message}</div>  }
      </div>
    </div>
    </div>
    </>
  )
}

export default AIMatrix