import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Calculator from '../calculator/Calculator'
import { useDispatch, useSelector } from 'react-redux';
import './Notes.css'
import { LoadUserNoLoad } from '../../Actions/User'
import { useAlert } from 'react-alert'
import ReadCSV from '../Read_CSV/ReadCSV';
import { Link } from 'react-router-dom'
import Holdings from '../holdings/Holdings';
import SuggestAverage from '../SuggestAverage/SuggestAverage';
import DataMatrix from '../DataMatrix/DataMatrix';
import Notifications from '../Notification/Notification';
import ManualDataUpload from '../ManualDataUpload/ManualDataUpload';
import AIMatrix from '../AIMatrix/AIMatrix';
import PortFolioTrend from '../PortFolioTrend/PortFolioTrend';



const Notes = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [note, setNote] = useState('');
  const [id, setId] = useState("");
  const [calcOpen, setCalcOpen] = useState(false);
  const [trendAnalysisOpen, setTrendAnalysisOpen] = useState(false);
  // const [videoCompOpen,setVideoCompOpen] = useState(false);
  const [UploadDataOpen, setUploadDataOpen] = useState(false);
  const [holdingFundsOpen,setHoldingFundsOpen] = useState(false);
  const [dataMatrixOpen,setDataMatrixOpen] = useState(false);
  const [aiMatrixOpen,setAIMatrixOpen] = useState(false);
  const [notificationOpen,setNotificationOpen] = useState(false);
  const [manualUploadOpen,setManualUploadOpen] = useState(false);
  const [showSuggestAverage,setShowSuggestAverage] = useState(false);
  const { companyname } = useSelector(state => state.SidebarDate);
  const { date, blurActive } = useSelector(state => state.SidebarDate)
  const { notes } = useSelector(state => state.user.user)
  const { notifications } = useSelector(state => state.user)
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  const getNote = (e) => {
    e.preventDefault();
    setNote(e.target.value);

  }

  const Addnote = () => {
    try {
      if (note) {
        const obj = {
          note: note,
          date,
          id,
          cname: companyname,
        }
        axiosInstance.post("/postnotes", obj, {
          headers: {
            'x-access-token': localStorage.getItem('token')
          }
        }).then(res => {
          if (res.data === "Notes Added" || res.data === "Notes Updated") {
            dispatch(LoadUserNoLoad());
            alert.success(res.data)
          }
        })
        setId("");
        setNote("");
        dispatch({
          type: "clearCompanyName",
        })
      } else {

        alert.show("Please fill the Note")
      }
    } catch (error) {
      alert.error(error.response.data.message)
    }
  }

  const deleteNote = (id) => {
    try {
      axiosInstance.delete(`/deletenotes/${id}`, {
        headers: {
          'x-access-token': localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.data === "Note Deleted") {
            dispatch(LoadUserNoLoad());
          }
          alert.success(res.data);
        })
    } catch (error) {
      alert.error(error.response.data.message)
    }

  }

  const editNote = (id) => {
    window.scrollTo(0, 0);
    setId(id);
    const editnote = notes.find(ele => ele._id === id)
    setNote(editnote.note);
  }

  useEffect(() => {
    if (calcOpen) {
      dispatch({
        type: "BlurActive",
        payload: true
      })
    }

    if (!calcOpen) {
      dispatch({
        type: "BlurActive",
        payload: false
      })
    }
  }, [dispatch, calcOpen])

  useEffect(() => {
    if (trendAnalysisOpen) {
      dispatch({
        type: "BlurActive",
        payload: true
      })
    }

    if (!trendAnalysisOpen) {
      dispatch({
        type: "BlurActive",
        payload: false
      })
    }
  }, [dispatch, trendAnalysisOpen])

  useEffect(() => {
    if (showSuggestAverage) {
      dispatch({
        type: "BlurActive",
        payload: true
      })
    }

    if (!showSuggestAverage) {
      dispatch({
        type: "BlurActive",
        payload: false
      })
    }
  }, [dispatch, showSuggestAverage])

  useEffect(() => {
    if (manualUploadOpen) {
      dispatch({
        type: "BlurActive",
        payload: true
      })
    }

    if (!manualUploadOpen) {
      dispatch({
        type: "BlurActive",
        payload: false
      })
    }
  }, [dispatch, manualUploadOpen])

  useEffect(() => {
    if (UploadDataOpen) {
      dispatch({
        type: "BlurActive",
        payload: true
      })
    }

    if (!UploadDataOpen) {
      dispatch({
        type: "BlurActive",
        payload: false
      })
    }
  }, [dispatch, UploadDataOpen])

  useEffect(() => {
    if (holdingFundsOpen) {
      dispatch({
        type: "BlurActive",
        payload: true
      })
    }

    if (!holdingFundsOpen) {
      dispatch({
        type: "BlurActive",
        payload: false
      })
    }
  }, [dispatch, holdingFundsOpen])

  useEffect(() => {
    if (dataMatrixOpen) {
      dispatch({
        type: "BlurActive",
        payload: true
      })
    }

    if (!dataMatrixOpen) {
      dispatch({
        type: "BlurActive",
        payload: false
      })
    }
  }, [dispatch, dataMatrixOpen])

  useEffect(() => {
    if (aiMatrixOpen) {
      dispatch({
        type: "BlurActive",
        payload: true
      })
    }

    if (!aiMatrixOpen) {
      dispatch({
        type: "BlurActive",
        payload: false
      })
    }
  }, [dispatch, aiMatrixOpen])

  useEffect(() => {
    if (notificationOpen) {
      dispatch({
        type: "BlurActive",
        payload: true
      })
    }

    if (!notificationOpen) {
      dispatch({
        type: "BlurActive",
        payload: false
      })
    }
  }, [dispatch, notificationOpen])


  // useEffect(() => {
  //  if(videoCompOpen){
  //   dispatch({
  //     type:"BlurActive",
  //     payload: true
  //    })
  //  }

  //  if(!videoCompOpen){
  //   dispatch({
  //     type:"BlurActive",
  //     payload: false
  //    })
  //  }
  // }, [dispatch,videoCompOpen])



  return (
    <>
      <div className={blurActive ? " blur main-body" : "main-body"}>
        <div className='notes'>
          <div className="main-notes">
            <h3 className="notehead">Add Notes</h3>
            <div className='note_cname'>
              {companyname && <h6 className='cnameh6'>{companyname}</h6>}
              <textarea rows={6} type="text" placeholder='Write Your Note' className='inputnote' value={note} onChange={getNote} autoFocus />
            </div>
            <div className='btnaddnote' onClick={Addnote}>Add note</div>
            <hr />

            <h3 className="notehead">My Notes</h3>
            <div className="allnotes">
              {notes.map((ele, index) => {
                const { note, _id, cname, date } = ele;
                let noteArr;
                if(note?.split("<br/>")?.length > 3){
                  noteArr = note?.split("<br/>");
                }
                return (
                  <div className="box" key={index} >
                    <div className="titledate">
                      <div className="title">Note {index}</div>
                      <p className='notedate'>{date}</p>
                    </div>
                    <div className={cname === "none" ? "notewithcname" : "content cname-content"}>{cname}</div>
                    <div className="content">{noteArr && noteArr.length > 0 ?
                     noteArr.map(el => {
                      return (
                        <>
                        {el}
                        <br />
                        </>
                        
                        );
                     })
                    :
                    note                    
                    }</div>
                    <div className="btndiv">
                      <div className="btneditnote" onClick={() => editNote(_id)}>
                        <span className="material-icons edit-icon">edit_note</span>
                      </div>
                      <span className='hover-edit'>Edit</span>
                      <div className="btndeletenote" onClick={() => deleteNote(_id)}>
                        <span className="material-icons delete-icon">delete_outline</span>
                      </div>
                      <span className='hover-delete'>Delete</span>

                    </div>
                  </div>
                )
              })}

            </div>
          </div>
          <div className="notes-sidebar">

            <img src="./images/calculator.png" alt="calculator" className="calc-icon" onClick={() => setCalcOpen(true)} />
            <span className="hover_calc">Calculator</span>

            <img src="./images/portfolio.webp" alt="calculator" className="calc-icon" onClick={() => setTrendAnalysisOpen(true)} />
            <span className="hover_calc">Porfolio</span>

            <img src="./images/statistics.png" alt="Analysis" className="analysis-icon" onClick={() => setUploadDataOpen(true)} />
            <span className="hover_analysis">Data Analysis</span>

            <img src="./images/endless.png" alt="library" className="calc-icon library" onClick={() => setHoldingFundsOpen(true)}/>
            <span className="hover-library">Infinity</span>

            <img src="./images/progress.png" alt="chess" className="calc-icon chess-icon" onClick={() => setShowSuggestAverage(true)}/>
            <span className="hover-chess">Money Manager</span>

          <div style={{position:"relative"}}  onClick={() => setNotificationOpen(true)}> <img src="./images/notification-bell.png" alt="bell" className="calc-icon notification" />
           {notifications.length > 0 && <span className='notification-count'>{notifications.length}</span>} 
           </div>
            <span className="hover-notification">Notification</span>


            <img src="./images/DataMatrics.png" alt="gift" className="calc-icon gift" onClick={() => setDataMatrixOpen(true)} />
            <span className="hover-giftcard">Data Matrix</span>

            <img src="./images/AIMatrix.png" alt="chatbot" className="calc-icon chatbot-icon" onClick={() => setAIMatrixOpen(true)} />
            <span className="hover-chess">AI Matrix</span>

            <img src="./images/bot.png" alt="upload" className="calc-icon chatbot-icon" onClick={() => setManualUploadOpen(true)} />
            <span className="hover-chess">Manual Upload</span>
          </div>
        </div>
      </div>
      {calcOpen && < Calculator setCalcOpen={setCalcOpen} />}
      { trendAnalysisOpen   && < PortFolioTrend setTrendAnalysisOpen={setTrendAnalysisOpen} />}


      {UploadDataOpen && < ReadCSV setUploadDataOpen={setUploadDataOpen} />}
      {showSuggestAverage && < SuggestAverage setShowSuggestAverage={setShowSuggestAverage} />}
      {manualUploadOpen && < ManualDataUpload setManualUploadOpen={setManualUploadOpen} />}
      {holdingFundsOpen && <Holdings setHoldingFundsOpen={setHoldingFundsOpen}/>  }
      {dataMatrixOpen && <DataMatrix setDataMatrixOpen={setDataMatrixOpen} />}
      {notificationOpen && <Notifications setNotificationOpen={setNotificationOpen} />}
      {aiMatrixOpen && <AIMatrix setAIMatrixOpen={setAIMatrixOpen}/>}
    </>
  )
}

export default Notes;